import React from "react";
import { tableIcons } from '../../../../../constants/TableConstants'
import MaterialTable, {MTableToolbar} from 'material-table'
import apiService from "../../../../../service/apiService";
import CustomFilterRow from '../CustomFilterRow';
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Moment from "react-moment";
const axios = require('axios');


class CustomMethods extends React.Component {
    
    // function that gets remote data
    fetchData = query => new Promise((resolve, reject) => {
        //deviceKey is deviceId
        const {deviceKey} = this.props.match.params;

        let url = `${apiService.BASE_URL}devices/${deviceKey}/custom-methods`;

        let token = localStorage.getItem("token");
        let config = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }

        axios.get(url, config)
        .then(result => {
            if(result.status===200){
                resolve({
                    data: result.data,
                })
            }else{
                reject({responseStatus: result.status})
            }
        })
    })

    render() {
        const { history,intl,match } = this.props;
        const {deviceKey} = match.params;

        return (
            <div style={{ maxWidth: '100%' }}>

                {deviceKey && <ContainerHeader match={{path:history.location.pathname, url: history.location.pathname}} title={<IntlMessages id="appModule.deviceCustomMethod"/>}/> }
                
                <MaterialTable
                    title={''}
                    onRowClick={(event, rowData, togglePanel) => {
                        history.push(history.location.pathname+'/'+rowData.id, rowData)
                    }}
                    columns={[
                    {
                        field: "dateCreated",
                        title: intl.formatMessage({id: 'audit.timestamp'}),
                        defaultSort: "desc",
                        filtering: false,
                        render: (data)=> {
                            const dateToFormat = new Date(data.dateCreated);
                            return <Moment format="YYYY/MM/DD  HH:mm:ss " date={dateToFormat}/>;
                        }
                    },
                    {
                        field: "name",
                        title: intl.formatMessage({id: 'custom-method.name'}),
                        type: "text",
                        filtering: false
                    },
                    // {
                    //     field: "isRefillEnabled",
                    //     title: intl.formatMessage({id: 'custom-method.refill'}),
                    //     type: "text",
                    //     filtering: false,
                    //     render: (data)=> {
                    //         if(data.isRefillEnabled)
                    //             return  intl.formatMessage({id: 'custom-method.refill.enabled'})
                    //         return  intl.formatMessage({id: 'custom-method.refill.disabled'})
                    //     }
                    // },
                    {
                        field: "valveModeLeft",
                        title: intl.formatMessage({id: 'custom-method.left.valve.mode'}),
                        type: "text",
                        filtering: false,
                        lookup: {0: "Dual dilutor",1:"Dual dispense",2: "Continuous dispense"},
                        emptyValue:"UNKNOWN"
                    },
                    {
                        field: "valveModeRight",
                        title: intl.formatMessage({id: 'custom-method.right.valve.mode'}),
                        type: "text",
                        filtering: false,
                        lookup: {0: "Dual dilutor",1:"Dual dispense",2: "Continuous dispense"},
                        emptyValue:"UNKNOWN"
                    },
                    {
                        field: "sizeLeftSyringeInUL",
                        title: intl.formatMessage({id: 'custom-method.left.syringe.size'}),
                        type: "text",
                        filtering: false
                    },
                    {
                        field: "sizeRightSyringeInUL",
                        title: intl.formatMessage({id: 'custom-method.right.syringe.size'}),
                        type: "text",
                        filtering: false
                    },
                    {
                        field: "details",
                        clickable: false,
                        title: intl.formatMessage({id: 'custom-method.details'}),
                        filtering: false,
                        render: rowData => {
                            let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(rowData, null, "\t"));
                            let fileName = rowData.dateCreated;
                            return (<Typography>
                                <MuiLink href={"data:"+data} download={`${fileName}.json`} underline='always'>JSON</MuiLink>
                            </Typography>)
                        }
                    }
                ]}
                    data={this.fetchData}
                    icons={tableIcons}
                    options={{
                        filtering: false,
                        search: false,
                        exportButton: false,
                        paging: false,
                        sorting:false,
                        draggable: false
                    }}
                    components={{
                        FilterRow: CustomFilterRow,
                        Toolbar: props => <MTableToolbar {...props} />,
                    }}
                    localization={{
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: intl.formatMessage({id: 'table.emptyDataSourceMessage'}),
                        }
                    }}

                />

            </div>
        );
    }
}

export default injectIntl(CustomMethods);
