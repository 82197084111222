import React from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from "react-intl";
import Typography from '@material-ui/core/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import apiService from "../../../../../service/apiService";

const axios = require('axios');


const initialErrorState = {
  currentPasswordHelpText: '',
  newPasswordHelpText: '',
  confirmNewPasswordHelpText: '',
  formHelpText: ''
}

class UserProfile extends React.Component {
  state = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',

    currentPasswordHelpText: '',
    newPasswordHelpText: '',
    confirmNewPasswordHelpText: '',
    formHelpText: ''
  }

  passwordChangeRequest = (currentPassword, newPassword) => {
    let token = localStorage.getItem("token");
    axios.post(`${apiService.BASE_URL}api/passwordreset`,
    {
      password: currentPassword,
      newPassword: newPassword
    },
    {
      headers: {
          'Authorization': 'Bearer '+ token,
          'Content-Type': 'application/json',
          'Accept': 'application/json'

      }
    }
    )
    .then(res=>{
      // console.log("SUCCESS");
      // console.log(res);
      const newState = initialErrorState;
      
      if(res.data === true){
        newState.formHelpText = 'Password changed successfully.';
      }

      this.setState(newState);      

    })
    .catch(err=>{
      // console.log("ERROR");
      // console.log(err.response);
      const res = err.response;
      const newState = initialErrorState;
      if(res.data.description === 'user.password.wrong'){
        newState.currentPasswordHelpText = 'Wrong Password.';
        // newState.formHelpText = 'Wrong Password.';
      }

      this.setState(newState);
      
    });
  }
  
  validationPassed(){
    return !(this.state.currentPasswordHelpText !== '' || this.state.newPasswordHelpText!== '' || this.state.confirmNewPasswordHelpText!== '')
  }

  render(){
    var user = JSON.parse(localStorage.getItem("user"));
    // let username = user&&(user.firstName + " " + user.lastName);
    // let avatar = user&&(user.firstName.charAt(0) + user.lastName.charAt(0));
    // console.log(user);

    return (
      <div className="jr-card text-center">
        <div className="jr-card-header-color mt-1">
              <Typography variant="h4">
                <FormattedMessage id="user.userProfile" />
              </Typography>
        </div>
        <div className="jr-card-body pt-2 container">

          <div className="row align-items-center mt-3">
            <div className="col-sm-4 ">
              <Typography variant="h6">
                <FormattedMessage id="user.firstName" />
              </Typography>
            </div>
            <div className="col-sm-8">
              <TextField
                      type="text"
                      onChange={{}}
                      fullWidth
                      defaultValue={user.firstName}
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />

            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-sm-4 ">
              <Typography variant="h6">
                <FormattedMessage id="user.lastName" />
              </Typography>
            </div>
            <div className="col-sm-8">
              <TextField
                      type="text"
                      onChange={{}}
                      fullWidth
                      defaultValue={user.lastName}
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />

            </div>
          </div>
          <div className="row align-items-center mt-3">
            <div className="col-sm-4 ">
              <Typography variant="h6">
                <FormattedMessage id="user.email" />
              </Typography>
            </div>
            <div className="col-sm-8">
              <TextField
                      type="text"
                      onChange={{}}
                      fullWidth
                      defaultValue={user.email}
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />

            </div>
          </div>
          {/* <div className="row align-items-center mt-3">
            <div className="col-sm-4 ">
              <Typography variant="h6">
                <FormattedMessage id="user.role" />
              </Typography>
            </div>
            <div className="col-sm-8">
              <TextField
                      type="text"
                      onChange={{}}
                      fullWidth
                      defaultValue={user.role}
                      margin="dense"
                      InputProps={{
                        readOnly: true,
                      }}
                      variant="outlined"
                    />

            </div>
          </div> */}
          <div className="row align-items-center mt-3">
            <div className="col-sm-4 ">
              <Typography variant="h6">
                <FormattedMessage id="user.password" />
              </Typography>
            </div>
            <div className="col-sm-8">
            <TextField
                  type="password"
                  error={this.state.currentPasswordHelpText !== '' ? true : false}
                  helperText={this.state.currentPasswordHelpText}
                  onChange={(event) => this.setState({ currentPassword: event.target.value })}
                  fullWidth
                  label={<FormattedMessage id="user.currentPassword" />}
                  defaultValue={''}
                  margin="normal"
                  className=""
                  variant="outlined"
                />
              <TextField
                  type="password"
                  error={this.state.newPasswordHelpText !== '' ? true : false}
                  helperText={this.state.newPasswordHelpText}
                  onChange={(event) => this.setState({ newPassword: event.target.value })}
                  label={<FormattedMessage id="user.newPassword" />}
                  fullWidth
                  defaultValue={''}
                  margin="normal"
                  className=""
                  variant="outlined"
                />

                <TextField
                  type="password"
                  error={this.state.confirmNewPasswordHelpText !== '' ? true : false}
                  helperText={this.state.confirmNewPasswordHelpText}
                  onChange={(event) => this.setState({ confirmNewPassword: event.target.value })}
                  label={<FormattedMessage id="user.confirmNewPassword" />}
                  fullWidth
                  defaultValue={''}
                  margin="normal"
                  className=""
                  variant="outlined"
                />

                { this.state.formHelpText!=='' && <FormHelperText className="font-weight-bold text-success">
                    {this.state.formHelpText}
                  </FormHelperText>
                }
                <Button variant="contained" className="mt-2" color="primary" 
                    onClick={() => {
                      let newState = {
                        currentPasswordHelpText: '',
                        newPasswordHelpText: '',
                        confirmNewPasswordHelpText: '',
                      };
                      if(this.state.newPassword.length < 5){
                        newState.newPasswordHelpText = 'Password length at least 5.';
                      
                      }else if(this.state.confirmNewPassword !== this.state.newPassword) {
                        newState.confirmNewPasswordHelpText = 'Passwords do not match.';
                      }
                      if(this.state.currentPassword === '') {
                        newState.currentPasswordHelpText = 'Empty field.';
                      }
                      if(this.state.newPassword === '') {
                        newState.newPasswordHelpText = 'Empty field.';
                      }

                      this.setState(newState, () => {
                        if(this.validationPassed())
                        this.passwordChangeRequest(this.state.currentPassword, this.state.newPassword);
                      });

                      
                    }} >
                    <FormattedMessage id="user.changePassword" />
                </Button>

            </div>
          </div>
        
        </div>
      </div>
  )}
};

export default UserProfile;

