import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import { hideMessage, resetAuthState, showAuthLoader, userSignIn } from 'actions/Auth';
import { switchLanguage } from 'actions/Setting';
import LanguageSwitcher from '../components/LanguageSwitcher';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import Footer from "../components/Footer";
import { getCurrentAppMode, appModes } from "../service/apiService";

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            langSwitcher: false
        }
    }

    componentDidMount() {
        // console.log("RESET HERE");
        this.props.resetAuthState();
    }

    componentDidUpdate() {
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }

    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
        })
    };

    handleRequestClose = () => {
        this.setState({
            langSwitcher: false,
        });
    };

    doLogin = () => {
        this.props.hideMessage();
        localStorage.setItem("lastActivityTime", Date.now());
        let newState = { emailError: false, passwordError: false };
        if (this.state.email.length === 0) newState.emailError = true;
        if (this.state.password.length < 6) newState.passwordError = true;
        this.setState(newState, () => {
            if (!(this.state.emailError || this.state.passwordError)) {
                this.props.showAuthLoader();
                const email = this.state.email;
                const password = this.state.password;
                this.props.userSignIn({ email, password });
            }
        })
    }

    keyDown = (event) => {
        if (event.keyCode === 13) {
            this.doLogin();
        }
    }

    render() {
        const {
            email,
            password
        } = this.state;
        const { showMessage, loader, alertMessage, locale } = this.props;
        return (
            <div className="app-main" onKeyDown={(e) => this.keyDown(e)}>
                <main className="app-main-content-wrapper">
                    <div className="app-main-content d-flex justify-content-center align-items-center">
                        <div
                            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                            <div className="app-login-main-content">

                                <div className="app-logo-content d-flex align-items-center justify-content-center">
                                    <Link className="logo-lg" to="/" title="Hamilton">
                                        <img src={require("assets/images/main/hamilton-white.png")} alt="Hamilton"
                                            title="Hamilton" />
                                    </Link>
                                </div>

                                <div className="app-login-content">
                                    <div className="app-login-header mb-4 "
                                        style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <h1><IntlMessages id="appModule.signIn" /></h1>
                                        <Dropdown
                                            className="quick-menu"
                                            isOpen={this.state.langSwitcher}
                                            toggle={this.onLangSwitcherSelect.bind(this)}>

                                            <DropdownToggle
                                                className="d-inline-block"
                                                tag="span"
                                                data-toggle="dropdown">
                                                <IconButton className="icon-btn">
                                                    <i className={`flag flag-24 flag-${locale.icon}`} />
                                                </IconButton>
                                            </DropdownToggle>

                                            <DropdownMenu right className="w-50">
                                                <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                                    handleRequestClose={this.handleRequestClose} />
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                    {showMessage &&
                                        <div class="alert alert-danger" role="alert"><IntlMessages id={alertMessage} />
                                        </div>}
                                    <div className="app-login-form">
                                        <form>
                                            <fieldset>
                                                <TextField
                                                    label={<IntlMessages id="user.email" />}
                                                    fullWidth
                                                    onChange={(event) => this.setState({ email: event.target.value })}
                                                    defaultValue={email}
                                                    margin="normal"
                                                    className="mt-1 my-sm-3"
                                                    error={this.state.emailError}
                                                />
                                                <TextField
                                                    type="password"
                                                    label={<IntlMessages id="user.password" />}
                                                    fullWidth
                                                    onChange={(event) => this.setState({ password: event.target.value })}
                                                    defaultValue={password}
                                                    margin="normal"
                                                    className="mt-1 my-sm-3"
                                                    error={this.state.passwordError}
                                                />

                                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                                    <Button onClick={() => {
                                                        this.doLogin()
                                                    }} variant="contained" color="primary">
                                                        <IntlMessages id="appModule.signIn" />
                                                    </Button>

                                                    <Link to="/signup">
                                                        <IntlMessages id="appModule.signUp" />
                                                    </Link>
                                                </div>
                                                <div className="app-social-block my-1 my-sm-3">
                                                    <Link to="/forgotpassword">
                                                        {
                                                            getCurrentAppMode() == appModes.offline ?
                                                                <IntlMessages id="appModule.resetAdmin" /> :
                                                                <IntlMessages id="appModule.forgotPassword" />
                                                        }
                                                    </Link>
                                                </div>

                                            </fieldset>
                                        </form>
                                    </div>
                                </div>

                            </div>
                            {
                                loader &&
                                <div className="loader-view">
                                    <CircularProgress />
                                </div>
                            }
                            {/* {showMessage && NotificationManager.error(<IntlMessages id={alertMessage} />)} */}
                            {/* <NotificationContainer /> */}
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        );
    }
}

const mapStateToProps = ({ auth, settings }) => {
    const { loader, alertMessage, showMessage, authUser } = auth;
    const { locale } = settings;
    return { loader, alertMessage, showMessage, authUser, locale }
};

export default connect(mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader,
    resetAuthState,
    switchLanguage
})(SignIn);
