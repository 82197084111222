import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import {useHistory, useLocation} from 'react-router-dom';
import { FormattedMessage } from "react-intl";

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    
  },
}))(MenuItem);

export default function CustomizedMenus(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let history = useHistory();
  let location = useLocation();
  const {id, deviceKey} = props;
  let devicePath = location.pathname;
  let devicePathWithId = null;
  let devicePathWithKey = null;
  const spl = location.pathname.split('/');
  if(spl[spl.length-1] === "devices") {
      devicePathWithKey = devicePath + "/"+deviceKey+"/";
      devicePathWithId = devicePath + "/"+id+"/";
  }; //WARNING might break with different urls


  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        <FormattedMessage id="device.more"/>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem onClick={()=>{history.push(devicePathWithKey+"logs"); }}>
          <ListItemText primary={<FormattedMessage id="appModule.devices.logs"/>} />
        </StyledMenuItem>
        <StyledMenuItem onClick={()=>{history.push(devicePathWithId+"audittrail"); }} >
          <ListItemText primary={<FormattedMessage id="appModule.auditTrail"/>} />
      </StyledMenuItem>
      <StyledMenuItem onClick={()=>{history.push(devicePathWithId+"favourites"); }} >
          <ListItemText primary={<FormattedMessage id="appModule.device.favourites"/>} />
      </StyledMenuItem>
      <StyledMenuItem onClick={()=>{history.push(devicePathWithId+"custom-methods"); }} >
          <ListItemText primary={<FormattedMessage id="appModule.device.customMethods"/>} />
      </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
