import React from 'react';
import { FormattedMessage } from "react-intl";
import Typography from '@material-ui/core/Typography';

function millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    // return minutes;
    const seconds = ((millis % 60000) / 1000).toFixed(0);
    return (parseInt(seconds) === 60 ? (minutes+1) + ":00" : minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
  }

export default class Timer extends React.Component {
    state = {
        timeString: ''
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            let timeString = millisToMinutesAndSeconds(this.props.getRemainingTime());
            this.setState({timeString : timeString})
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render(){
        return (
            <div>
                <Typography variant="caption" >
                    <FormattedMessage id="timer.text" values={{time: this.state.timeString}} /> 
                </Typography>
            </div>
        );
    }

}