import React from "react";
import { tableIcons } from '../../../../../constants/TableConstants'
import MaterialTable, {MTableToolbar} from 'material-table'
import apiService from "../../../../../service/apiService";
import CustomFilterRow from '../CustomFilterRow';
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import { injectIntl } from "react-intl";
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Moment from "react-moment";
const axios = require('axios');

class Favourites extends React.Component {
    // function that gets remote data
    fetchData = query => new Promise((resolve, reject) => {
        //deviceKey is deviceId
        const {deviceKey} = this.props.match.params;

        let url = `${apiService.BASE_URL}devices/${deviceKey}/favourites`;

        let token = localStorage.getItem("token");
        let config = {
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }

        axios.get(url, config)
            .then(result => {
                if(result.status===200){
                    resolve({
                        data: result.data,
                    })
                }else{
                    reject({responseStatus: result.status})
                }
            })
    })

    render() {
        const { history,intl,match } = this.props;
        const {deviceKey} = match.params;

        return (
            <div style={{ maxWidth: '100%' }}>

                {deviceKey && <ContainerHeader match={{path:history.location.pathname, url: history.location.pathname}} title={<IntlMessages id="appModule.deviceFavourite"/>}/> }

                <MaterialTable
                    title={''}
                    columns={[
                        {
                            field: "favouriteDate",
                            title: intl.formatMessage({id: 'favourite.timestamp'}),
                            defaultSort: "desc",
                            filtering: false,
                            render: (data)=> {
                                const dateToFormat = new Date(data.favouriteDate);
                                return <Moment format="YYYY/MM/DD  HH:mm:ss " date={dateToFormat}/>;
                            }
                        },
                        {
                            field: "favouriteName",
                            title: intl.formatMessage({id: 'favourite.name'}),
                            type: "text",
                            filtering: false
                        },
                        {
                            field: "wizardType",
                            title: intl.formatMessage({id: 'favourite.wizard.type'}),
                            type: "text",
                            filtering: false,
                            lookup: {
                            0 : "None", 
                            1 : intl.formatMessage({id: 'config.aliquot'}), 
                            2 : intl.formatMessage({id: 'config.serial_dispense'}), 
                            3 : intl.formatMessage({id: 'config.dilution'}), 
                            4 : intl.formatMessage({id: 'config.dry_dilution'}), 
                            5 : intl.formatMessage({id: 'config.titration'}), 
                            6 : intl.formatMessage({id: 'config.pipette'}), 
                            7 : "Any", 
                            8 : "Quickstart" 
                        },
                            emptyValue:"UNKNOWN"
                        },
                        {
                            field: "valveModeLeft",
                            title: intl.formatMessage({id: 'favourite.left.valve.mode'}),
                            type: "text",
                            lookup: {0: "Dual dilutor",1:"Dual dispense",2: "Continuous dispense"},
                            emptyValue:"UNKNOWN"
                        },
                        {
                            field: "valveModeRight",
                            title: intl.formatMessage({id: 'favourite.right.valve.mode'}),
                            type: "text",
                            lookup: {0: "Dual dilutor",1:"Dual dispense",2: "Continuous dispense"},
                            emptyValue:"UNKNOWN"
                        },
                        {
                            field: "sizeLeftSyringe",
                            title: intl.formatMessage({id: 'favourite.left.syringe.size'}),
                            type: "text",
                            filtering: false
                        },
                        {
                            field: "sizeRightSyringe",
                            title: intl.formatMessage({id: 'favourite.right.syringe.size'}),
                            type: "text",
                            filtering: false
                        },
                        //
                        // {
                        //     field: "dispenseSpeedLeft",
                        //     title: intl.formatMessage({id: 'favourite.left.dispense.speed'}),
                        //     type: "numeric",
                        //     filtering: false
                        // },
                        // {
                        //     field: "dispenseSpeedRight",
                        //     title: intl.formatMessage({id: 'favourite.right.dispense.speed'}),
                        //     type: "numeric",
                        //     filtering: false
                        // },
                        {
                            field: "details",
                            title: intl.formatMessage({id: 'favourite.details'}),
                            filtering: false,
                            render: rowData => {
                                let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(rowData, null, "\t"));
                                let fileName = rowData.favouriteDate;
                                return (<Typography>
                                    <MuiLink href={"data:"+data} download={`${fileName}.json`} underline='always'>JSON</MuiLink>
                                </Typography>)
                            }
                        }
                    ]}
                    data={this.fetchData}
                    icons={tableIcons}
                    options={{
                        filtering: false,
                        search: false,
                        exportButton: false,
                        paging: false,
                        sorting:false,
                        draggable: false
                    }}
                    components={{
                        FilterRow: CustomFilterRow,
                        Toolbar: props => <MTableToolbar {...props} />,
                    }}
                    localization={{
                        header: {
                            actions: ''
                        },
                        body: {
                            emptyDataSourceMessage: intl.formatMessage({id: 'table.emptyDataSourceMessage'}),
                        }
                    }}

                />

            </div>
        );
    }
}

export default injectIntl(Favourites);
