import { appModes, getCurrentAppMode } from "../service/apiService";
export const WS_MESSAGE_TYPE = {
  DEVICE_OFFLINE: "OFFLINE_DEVICE",
  DEVICE_ONLINE: "ONLINE_DEVICE",
  DEVICE_STATUS: "STATUS_DEVICE",

  PRIME_DEFAULT: "PRIME_DEFAULT",
  PRIME_SUCCESS: "PRIME_DEFAULT_INVOKED_SUCCESSFULLY",
  PRIME_FAILURE: "PRIME_DEFAULT_INVOKED_UNSUCCESSFULLY",

  CONFIGURATION: "CONFIGURATION",
  GET_CONFIGURATION: "GET_CONFIGURATION",
  SET_CONFIGURATION: "SET_CONFIGURATION",
  SAVE_SUCCESSFUL: "CONFIGURATION_CHANGED_SUCCESSFULLY",
  SAVE_UNSUCCESSFUL: "CONFIGURATION_CHANGED_UNSUCCESSFULLY",
};

export const WS_URL =
  getCurrentAppMode() == appModes.prod
    ? "wss://hamilton-lyncstore-backend.herokuapp.com/websocket"
    : getCurrentAppMode() == appModes.staging
    ? "wss://lyncstore-backend-staging.herokuapp.com/websocket"
    : getCurrentAppMode() == appModes.offline
    ? "ws://localhost:8080/websocket"
    : "ws://localhost:8080/websocket";

export const WS_DEVICE_TOPIC = "/topic/client/";
export const WS_TO_DEVICE = "/app/client-device/";
export const WS_TIMEOUT = 30000;
