import React from 'react';
import {connect} from 'react-redux'
import {userSignOut} from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import Avatar from '@material-ui/core/Avatar';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';

class UserInfoPopup extends React.Component {
  state = {
    username: "",
    avatar: ""
  };
  render() {
    var { username, avatar } = this.props;
    var user = JSON.parse(localStorage.getItem("user"));
    // console.log(user);
    username = user&&(user.firstName + " " + user.lastName);
    avatar = user&&(user.firstName.charAt(0) + user.lastName.charAt(0));
    return (
      <div >
        <div className="user-profile"  display="flex" flexWrap="nowrap" p={1} m={1}>
            <Box display="flex" p={1} style={{paddingLeft:0}} >
                <Avatar p={1} alt='...' className="size-30" >{avatar}</Avatar>
          {/* <img className="user-avatar border-0 size-40 rounded-circle"
               src='https://via.placeholder.com/150x150'
               alt="User"/> */}
                  <div className="user-detail ml-2" p={5}>
                    <h4 className="user-name mb-0">{username}</h4>
                    {/* <small>{user.role}</small> */}
                  </div>
            </Box>
        </div>
        <Link to="/app/main/profile">
        <span className="jr-link dropdown-item text-muted">
          <i className="zmdi zmdi-face zmdi-hc-fw mr-1"/>
          <IntlMessages id="userPopup.profile"/>
        </span>
        </Link>
        <span className="jr-link dropdown-item text-muted" onClick={() => {
          console.log("Try to logoput");
          this.props.userSignOut()
        }}>
          <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-1"/>
          <IntlMessages id="userPopup.logout"/>
        </span>
      </div>
    );
  }
}

export default connect(null, {userSignOut})(UserInfoPopup);


