import axios from "axios";
import { history } from "../store";

//const BASE_URL = "http://localhost:8080/";
const appModes = {
  prod: "PRODUCTION",
  staging: "STAGING",
  offline: "OFFLINE",
  debug: "DEBUG",
};

function getCurrentAppMode() {
  console.warn(process.env.REACT_APP_APPLICATION_MODE);
  return process.env.REACT_APP_APPLICATION_MODE;
}

const reCaptchaKey = "6LdAZ7AaAAAAAITUK1OIRCQK4WEG86xC6nLVABdH";

function getBaseUrl() {
  var appMode = getCurrentAppMode();
  switch (appMode) {
    case appModes.prod:
      return "https://hamilton-lyncstore-backend.herokuapp.com/";
    case appModes.staging:
      return "https://lyncstore-backend-staging.herokuapp.com/";
    case appModes.offline:
      return "http://localhost:8080/";
    default:
      return "http://localhost:8080/";
  }
}

const BASE_URL = getBaseUrl();

function createRequest() {
  let token = localStorage.getItem("token");
  let authHeader = token ? { Authorization: "Bearer " + token } : {};
  let request = axios.create({
    baseURL: BASE_URL,
    headers: authHeader,
    timeout: 2000,
  });

  request.interceptors.response.use(
    (res) => res,
    (err) => {
      if (err.response && err.response.status === 401) {
        history.push("/signin");
      }
      if (err.response && err.response.status === 403) {
        history.push("/signin");
      }
      return Promise.reject(err);
    }
  );

  return request;
}

function signin(username, password) {
  var jsonBody = {
    username: username,
    password: password,
  };
  return axios.post(`${BASE_URL}api/authenticate`, jsonBody);
}

//For user activation with link
function activate(activationId) {
  // return axios
  // .get(`${BASE_URL}activateUser/${activationId}`);
  return createRequest().get("activateUser/" + activationId);
}

//For user unblock with link
function unblock(unblockId) {
  return createRequest().get("unblock/" + unblockId);
}

function signup(user) {
  return axios.post(`${BASE_URL}register`, user);
}

function signout() {
  return createRequest().get("auth/signout");
}

function createSource() {
  return axios.CancelToken.source();
}

function isCancel(err) {
  return axios.isCancel(err);
}

function all(requests, callback) {
  return axios.all(requests).then(axios.spread(callback));
}

const apiService = {
  activate,
  unblock,
  signin,
  signout,
  createRequest,
  createSource,
  isCancel,
  signup,
  all,
  BASE_URL,
};
export default apiService;
export { getCurrentAppMode, appModes, reCaptchaKey };
