import React from "react";
import { tableIcons } from '../../../../../constants/TableConstants'
import MaterialTable from 'material-table'
import apiService from "../../../../../service/apiService";
import { FormattedMessage, injectIntl } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';

import MenuButton from './components/MenuButton';
import RemoteMenu from './components/RemoteMenu';
import ProgressWithAbort from '../ProgressWithAbort';
import { WS_MESSAGE_TYPE, WS_URL, WS_DEVICE_TOPIC, WS_TO_DEVICE, WS_TIMEOUT } from '../../../../../constants/WebSocket'
import { Image } from "@amcharts/amcharts4/core";
import { Row } from "reactstrap";
import { Column } from "@amcharts/amcharts4/charts";
import { FiEdit } from "react-icons/fi"
import { TextField, Box } from '@material-ui/core';
import { DeviceRow } from "./DeviceRow";


const axios = require('axios');
const StompJs = require('@stomp/stompjs');
const uuidv1 = require('uuid/v1');


const options = {
    filtering: false,
    search: true,
    sorting: false,
    exportButton: false,
    actionsColumnIndex: -1,
    headerStyle: {
        textAlign: "center"
    },
    paging: false,
    draggable: false,
    actionsCellStyle: {     //style for the cells with buttons
        // backgroundColor: 'black'
    }
};


// Fields of device not included in search
const notSearchableFields = ['id', 'tableData', 'key']


function isIterable(obj) {
    if (obj == null) {
        return false;
    }
    return typeof obj[Symbol.iterator] === 'function';
}


class Devices extends React.Component {
    constructor() {
        super();
        let token = localStorage.getItem("token");
        this.tableRef = React.createRef();   // TODO remove if unused
        this.client = new StompJs.Client({
            reconnectDelay: 300,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            connectHeaders: { 'Authorization': 'Bearer ' + token }

        })
        this.client.brokerURL = WS_URL;
        this.client.onConnect = () => {
            // console.log("CONNECTED - DEVICES");
            if (!isIterable(this.state.devices)) return;
            this.subscribeToDevices(this.state.devices);
        };
        this.client.onStompError = (error) => {
            console.log("STOMP ERROR");
            console.log(error);
        };

        this.state = {
            pendingRequest: null,   // ID of pending request. Not NULL activates CircularProgress
            wasSuccessful: false,
            dialogOpen: false,
            devices: undefined,   //shows loader when undefined -> check if is iterable before using
        }
    }

    // get data from API and subscribes WebSocket 
    componentDidMount() {
        // RETRIEVE DATA
        let url = apiService.BASE_URL + "devices";
        let token = localStorage.getItem("token");
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        axios.get(url, config)
            .then(result => {
                console.log("result")
                console.log(result.data)

                if (result.status === 200) {
                    let newState = { devices: result.data.content };
                    for (let dev of result.data.content) {
                        newState[dev.key] = dev.online;
                    }

                    this.setState(newState, () => {
                        // CONNECT TO WEB SOCKET (AND SUBSCRIBE TO DEVICES)
                        let token = localStorage.getItem("token");
                        this.client.activate();
                    });
                } else {
                    console.log(result.status);
                }

            })
            .catch(err => err);

    }

    componentWillUnmount() {
        this.client.deactivate();
    }

    components = {
        Action: props => {
            return (

                <div style={{ display: 'flex', flexDirection: 'column', width: "100%", marginRight: "50px" }}>
                    <MenuButton deviceKey={props.data.key} id={props.data.id} online={this.state[props.data.key]} />
                    <RemoteMenu id={props.data.key} online={this.state[props.data.key]} primeClick={() => { let reqId = uuidv1(); this.setState({ pendingRequest: reqId }, () => this.sendPrimeDefault(props.data.key, reqId)) }} />
                </div>
            )
        }
    }

    sendPrimeDefault = (deviceKey, reqId) => {
        console.log("PRIME DEFAULT to dev " + deviceKey);

        this.client.publish({
            destination: WS_TO_DEVICE + deviceKey,
            body: JSON.stringify({ Subject: WS_MESSAGE_TYPE.PRIME_DEFAULT, Id: reqId })
        });
        setTimeout(() => {
            if (this.state.pendingRequest && !this.state.wasSuccessful) {
                console.log("PRIME TIMEOUT EXPIRED")
                this.setState({
                    pendingRequest: null,
                    dialogOpen: true,
                    wasSuccessful: false
                })
            }
        }, WS_TIMEOUT);


    }

    // called when the client receives a STOMP message from the server
    onIncomingCallback = (message) => {
        console.log("INCOMING (in Devices)");
        if (message.body) {
            const body = JSON.parse(message.body)
            // console.log(body);
            if (body.Subject === undefined) {
                if (body.messageType === WS_MESSAGE_TYPE.DEVICE_ONLINE) {
                    console.log("DEVICE CONNECTED")
                    let newState = {};
                    newState[body.data.deviceKey] = true;
                    newState["_" + body.data.deviceKey] = true;
                    this.setState(newState);

                } else if (body.messageType === WS_MESSAGE_TYPE.DEVICE_OFFLINE) {
                    console.log("DEVICE DISCONNECTED");
                    let newState = {};
                    newState["_" + body.data.deviceKey] = false;
                    this.setState(newState);
                    setTimeout(() => {
                        newState[body.data.deviceKey] = this.state["_" + body.data.deviceKey]
                        this.setState(newState);
                    }, 5000)
                    //newState[body.data.deviceKey] = false;
                } else {
                    console.log('Unknown messageType:' + body.messageType)
                }
            } else if (this.state.pendingRequest === body.Id) {  //accepts only message with answer to request & only if still pending (not timed out)
                if (body.Subject === WS_MESSAGE_TYPE.PRIME_SUCCESS) {
                    // Show Dialog to report successful
                    this.setState({
                        pendingRequest: null,
                        dialogOpen: true,
                        wasSuccessful: true
                    })
                } else if (body.Subject === WS_MESSAGE_TYPE.PRIME_FAILURE) {
                    // Show Dialog to report unsuccessful
                    this.setState({
                        pendingRequest: null,
                        dialogOpen: true,
                        wasSuccessful: false
                    })
                } else {
                    console.log('Unknown Subject')
                }
            }
        } else throw Error('received no body');
    }

    subscribeToDevices = devices => {
        // console.log("SUBSCRIBE to devices")
        // console.log(devices);
        if (!isIterable(devices)) return;
        for (let dev of devices) {
            this.client.subscribe(WS_DEVICE_TOPIC + dev.key, this.onIncomingCallback);
        }
    }


    subscribeToDevices = devices => {
        // console.log("SUBSCRIBE to devices")
        // console.log(devices);
        if (!isIterable(devices)) return;
        for (let dev of devices) {
            this.client.subscribe(WS_DEVICE_TOPIC + dev.key, this.onIncomingCallback);
        }
    }


    handleDialogClose = () => {
        this.setState({
            dialogOpen: false
        })
    }

    handleAbort = () => {
        this.setState({
            pendingRequest: null,
            dialogOpen: false,
            wasSuccessful: false
        })
    }

    render() {
        const { intl } = this.props;
        // console.log("render DEVICES");
        // console.log(this.state)

        return (
            <div style={{ maxWidth: '100%' }}>

                <MaterialTable
                    title={intl.formatMessage({ id: "appModule.devices" })}
                    columns={[
                        {
                            field: "img",
                            title: intl.formatMessage({ id: 'device.devicePicture' }),
                            cellStyle: {
                                textAlign: "center"
                            },
                            headerStyle: {
                                width: 350
                            },
                            render: rowData => <img alt={""}
                                                    style={{width: "auto", height: "150px"}}
                                src={rowData.img ? rowData.img : '/dual_syringe_unit.png'}
                            />
                        },
                        {
                            field: "details",
                            title: intl.formatMessage({ id: 'device.details' }),
                            cellStyle: {},
                            // styling for this <dl> in app.scss
                            render: rowData => <DeviceRow deviceData={rowData} />,
                            customFilterAndSearch: (filter, rowData) => {
                                for (let x in rowData) {
                                    if (typeof rowData[x] != 'string' || notSearchableFields.includes(x)) continue;
                                    if (rowData[x].includes(filter)) return true;
                                }
                                return false;
                            }
                        }
                    ]
                    }
                    isLoading={!this.state.devices}
                    data={this.state.devices}
                    icons={tableIcons}
                    options={options}
                    actions={
                        [{
                        }]}
                    components={this.components}
                    localization={{
                        header: {
                            actions: ''
                        },
                        toolbar: {
                            searchTooltip: intl.formatMessage({ id: 'table.search' }),
                            searchPlaceholder: intl.formatMessage({ id: 'table.search' })
                        },
                        body: {
                            emptyDataSourceMessage: intl.formatMessage({ id: 'table.emptyDataSourceMessage' }),
                            filterRow: {
                                filterTooltip: intl.formatMessage({ id: 'table.filterTooltip' })
                            }
                        },
                        pagination: {
                            labelRowsSelect: intl.formatMessage({ id: 'table.rows' }),
                            labelDisplayedRows: intl.formatMessage({ id: 'table.page' }),
                            firstTooltip: intl.formatMessage({ id: 'table.firstPage' }),
                            previousTooltip: intl.formatMessage({ id: 'table.previousPage' }),
                            nextTooltip: intl.formatMessage({ id: 'table.nextPage' }),
                            lastTooltip: intl.formatMessage({ id: 'table.lastPage' }),
                        }
                    }}
                />

                {this.state.pendingRequest && <ProgressWithAbort onAbort={this.handleAbort} />}
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle id="verify-dialog-title"><FormattedMessage id="config.operationResult" /></DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.wasSuccessful ?
                                <FormattedMessage id="config.dialogSuccess" /> :
                                <FormattedMessage id="config.dialogFailure" />
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary" autoFocus>
                            <FormattedMessage id="dialog.ok" />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div >
        );
    }
}

export default injectIntl(Devices);
