import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Header from 'components/Header/index';
import Sidebar from 'containers/SideNav/index';
import Footer from 'components/Footer';
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
// import ColorOption from 'containers/Customizer/ColorOption';
import { isIOS, isMobile } from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import Main from "./routes/main";

import IdleTimer from 'react-idle-timer';
import { userSignOut } from 'actions/Auth';

const MINUTES_OF_INACTIVITY = 5; //user is logged out after inactive for this number of minutes

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      idleTimer: null
    }
    this.onIdle = this._onIdle.bind(this);
  }

  _onIdle(e) {
    // LOGOUT USER
    // this.props.userSignOut();
  }

  onAction = (e) => {
    var lastActivity = localStorage.getItem("lastActivityTime");

    var diff = Date.now() - lastActivity;

    if (diff / (60 * 1000) > MINUTES_OF_INACTIVITY * 1.2) {
      this.props.userSignOut();
      return;
    }

    localStorage.setItem("lastActivityTime", Date.now())
  }

  render() {
    const { match, drawerType, navigationStyle, horizontalNavPosition } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add('ios-mobile-view-height')
    }
    else if (document.body.classList.contains('ios-mobile-view-height')) {
      document.body.classList.remove('ios-mobile-view-height')
    }

    let getRemainingTime = undefined;
    if (this.state.idleTimer) getRemainingTime = this.state.idleTimer.getRemainingTime;

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour/> */}
        <IdleTimer
          ref={ref => { if (this.state.idleTimer === null) this.setState({ idleTimer: ref }) }}
          element={document}
          onIdle={this.onIdle}
          onAction={this.onAction.bind(this)}
          debounce={250}
          timeout={1000 * 60 * MINUTES_OF_INACTIVITY}
        />

        <Sidebar />
        <div className="app-main-container">
          <div
            className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
              <TopNav styleName="app-top-header" />}
            <Header getRemainingTime={getRemainingTime} />
            {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
              <TopNav />}
          </div>


          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <Switch>
                <Route path={`${match.url}/main`} component={Main} />
                <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))} />
              </Switch>
            </div>
            <Footer />
          </main>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;
  return { drawerType, navigationStyle, horizontalNavPosition }
};
export default withRouter(connect(mapStateToProps, { userSignOut })(App));