import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  hideMessage,
  showAuthLoader,
  userSignUp,
  activateUser,
  resetAuthState
} from 'actions/Auth';
import {Box} from "@material-ui/core";

class ActivationLink extends React.Component {
  constructor() {
    super();
    this.state = {
    }
  }

  componentDidMount() {
    const { activationId } = this.props.match.params;

    this.props.showAuthLoader();
    this.props.activateUser(activationId);

  }

  componentDidUpdate() {
    // if (this.props.showMessage) {
    //   setTimeout(() => {
    //     this.props.hideMessage();
    //   }, 3000);
    // }
    // if (this.props.authUser !== null) {
    //   this.props.history.push('/');
    // }
  }


  render() {
    const { history, showMessage, loader, alertMessage, showActivatedSuccess } = this.props;


    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Hamilton">
              <img src={require("assets/images/main/hamilton-white.png")}  alt="Hamilton" title="Hamilton" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header">
              <h1><IntlMessages id="appModule.userActivation" /></h1>
            </div>
            
            { showMessage && <div class="alert alert-danger" role="alert"><IntlMessages id={alertMessage} /></div> }

            {showActivatedSuccess && <IntlMessages id="activation.activatedSuccessMessage" />}

            <Box display="flex" justifyContent="flex-end" m={1} p={1}>
              <Box p={1}>
                <Button variant="contained" className="ml-2" color="primary" onClick={() => {history.push("/signin");}} >
                  <IntlMessages id="appModule.signIn" />
                </Button>
              </Box>
            </Box>

          </div>

        </div>

        { loader &&
          <div className="loader-view">
            <CircularProgress />
          </div>
        }
        {/* {showMessage && NotificationManager.error(<IntlMessages id={alertMessage} />)} */}
        {/* <NotificationContainer /> */}
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser, showActivatedSuccess } = auth;
  return { loader, alertMessage, showMessage, authUser, showActivatedSuccess }
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  activateUser,
  resetAuthState
})(ActivationLink);
