import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  ACTIVATE_USER,
  UNBLOCK_USER
} from "constants/ActionTypes";
import { showAuthMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess, userActivateSuccess, userUnblockSuccess } from "actions/Auth";
import apiService from '../service/apiService'

const createUserWithEmailPasswordRequest = async (user) =>
  await apiService.signup(user).then(user => user.data)
  .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await apiService.signin(email, password)
    .then(authUser => authUser.data)
    .catch(error => error);

const signOutRequest = async () =>
  await apiService.signout()
    .then(authUser => authUser)
    .catch(error => error);

// Activate user through link
const activateUserWithLinkRequest = async (activationId) =>
  await apiService.activate(activationId)
    .then(result => result)
    .catch(error => error);

function* activateUserWithLink({ payload }) {
  try {
    const activateUser = yield call(activateUserWithLinkRequest, payload);
    if (activateUser.data === false) {
      yield put(showAuthMessage("activation.invalidLink"));
    } else {
      yield put(userActivateSuccess(activateUser.id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}
 
// Unblock user through link
const unblockUserWithLinkRequest = async (unblockId) =>
  await apiService.unblock(unblockId)
    .then(result => result)
    .catch(error => error);

function* unblockUserWithLink({ payload }) {
  try {
    const unblockUser = yield call(unblockUserWithLinkRequest, payload);
    if (unblockUser.data === false) {
      yield put(showAuthMessage("unblock.invalidLink"));
    } else {
      yield put(userUnblockSuccess(unblockUser.id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* createUserWithEmailPassword({ payload }) {
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, payload);
    if (signUpUser.message) {
      if(signUpUser.response.data) yield put(showAuthMessage(signUpUser.response.data.description));
      else yield put(showAuthMessage(signUpUser.message));
    } else {
      // yield put(userSignUpSuccess(signUpUser.user.id));
      yield put(userSignUpSuccess(signUpUser.id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      if(signInUser.response.data) yield put(showAuthMessage(signInUser.response.data.description));
      else yield put(showAuthMessage(signInUser.message));
    } else {
      localStorage.setItem('user_id', signInUser.user.id);
      localStorage.setItem("user", JSON.stringify(signInUser.user));
      localStorage.setItem("token", signInUser.token);
      yield put(userSignInSuccess(signInUser.user.id));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
    const signOutUser = yield call(signOutRequest);
    localStorage.removeItem('user_id');
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    yield put(userSignOutSuccess(signOutUser));
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* activateUser() {
  yield takeEvery(ACTIVATE_USER, activateUserWithLink);
}

export function* unblockUser() {
  yield takeEvery(UNBLOCK_USER, unblockUserWithLink);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(createUserAccount),
    fork(signOutUser),
    fork(activateUser),
    fork(unblockUser)
  ]);
}
