import React from "react";
import { injectIntl } from "react-intl";
import ContainerHeader from "components/ContainerHeader/index";
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { tableIcons } from '../../../../../../constants/TableConstants'
import MaterialTable, { MTableToolbar} from 'material-table'
import apiService from "../../../../../../service/apiService";
import Moment from "react-moment";
const axios = require('axios');




// table's container style
const style = {
}

const styles = {
    formControl: {
      // minWidth: 120,
      // backgroundColor: 'rgb(255,0,0)',
    },
    list: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap'
      // maxWidth: '100%',
    },
    listItem: {
        width: 'fit-content'
    },
    listHeader: {
        backgroundColor: '#a6a6a6',
    },
    notClickableItem: {
      backgroundColor: '#ededed'
    },
    itemPrimary: {
      fontWeight: 'bold',
      fontSize: '1em'
    },
    itemSecondary: {
      fontWeight: 'normal',
      fontSize: '1.2em'
    },
    inputLabel: {
      fontWeight: 'bold',
      fontSize: '1.4em',
      color: '#525252',
   },
   divider: {
    // border: 'none',
    // height: '10px',
    // backgroundColor: '#525252',
    // color: '#525252',
   },

}

class LogDetails extends React.Component {
    state = {
        rows: [],
        totalCount: 0,
        dialogOpen: false,
        dialogType: "",
        selected: null,
        changeRequiredToggle: false,
        topics: [],
    };

    componentDidMount(){
        //if did not navigate here though Log Files page (for example pasting address) -> redirect
        if(this.props.history.location.state===undefined) {
            this.props.history.push('/app/main/devices')
        }
    }

    handleExportPdf = logData => {
        const logId = logData.id;
        let token = localStorage.getItem("token");
        axios.get(`${apiService.BASE_URL}devices/logs/${logId}/download/pdf`,{
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer '+ token,
                'Accept': 'application/pdf'
            }
        })
        .then(res => {
            // Download the file
            const blob = res.data;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', logData.fileName+'.pdf'); 
            document.body.appendChild(link);
            link.click();            
            
        })
        .catch(err=>err)
    }



    render() { 
        const { intl, history, classes } = this.props;
        const logData = history.location.state;

        return (
            <div style={{ maxWidth: '100%' }}>
                
                {logData &&  <React.Fragment> 
                    <ContainerHeader match={{path:history.location.pathname, url: history.location.pathname}} title={logData.fileName} />                
                    <MaterialTable
                        title={intl.formatMessage({id: 'log.logFileDetails'})}
                        style={style}
                        columns={[
                            {
                                field: "step",
                                title: intl.formatMessage({id: 'log.step'}),
                                type: "text",
                                defaultSort: "asc"
                            },
                            {
                                field: "rightVolumeChange",
                                title: intl.formatMessage({id: 'log.rightVolumeChange'}),
                                type: "text",

                            },
                            {
                                field: "rightValve",
                                title: intl.formatMessage({id: 'log.rightValve'}),
                                lookup: {"OUT": intl.formatMessage({id: 'valve.out'}), "IN": intl.formatMessage({id: 'valve.in'})},
                                filterCellStyle: {
                                    paddingTop: 0
                                }
                            },
                            {
                                field: "rightFlowRate",
                                title: intl.formatMessage({id: 'log.rightFlowRate'}),
                                type: "text"
                            },
                            {
                                field: "leftVolumeChange",
                                title: intl.formatMessage({id: 'log.leftVolumeChange'}),
                                type: "text"
                            },
                            {
                                field: "leftValve",
                                title: intl.formatMessage({id: 'log.leftValve'}),
                                lookup: {"OUT": intl.formatMessage({id: 'valve.out'}), "IN": intl.formatMessage({id: 'valve.in'})},
                                filterCellStyle: {
                                    paddingTop: 0
                                }
                            },
                            {
                                field: "leftFlowRate",
                                title: intl.formatMessage({id: 'log.leftFlowRate'}),
                                type: "text"
                            }
                        ]}
                        data={logData.details}
                        icons={tableIcons}
                        options={{
                            filtering: true,
                            search: false,
                            pageSize: 20,
                            draggable: false,
                            rowStyle: {
                            },
                            headerStyle: {
                            },
                            exportButton: true,
                            exportCsv: (columns, data) => {
                                this.handleExportPdf(logData);
                            }
                        }}
                        components={{
                            Toolbar: props => (
                                <div style={{ backgroundColor: '#e8eaf5' }}>
                                    <MTableToolbar {...props} />
                                    <Divider className={classes.divider}/>
                                    <List
                                    component="ul"
                                    classes={{root: classes.list}}
                                    >
                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                            primary={intl.formatMessage({id: 'log.date'})}
                                            secondary={<Moment format="YYYY/MM/DD  HH:mm:ss " date={new Date(logData.loggedAt)}/>}
                                            classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                            primary={intl.formatMessage({id: 'log.controller'})}
                                            secondary={logData.controller}
                                            classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                            primary={intl.formatMessage({id: 'log.fileName'})}
                                            secondary={logData.fileName}
                                            classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                        {/*<ListItem classes={{root:classes.listItem }} >*/}
                                        {/*    <ListItemText*/}
                                        {/*    primary={intl.formatMessage({id: 'log.userId'})} */}
                                        {/*    secondary={logData.userId}*/}
                                        {/*    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}*/}
                                        {/*    />*/}
                                        {/*</ListItem>*/}

                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                            primary={intl.formatMessage({id: 'log.customMethodName'})}
                                            secondary={logData.customMethodName}
                                            classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                    </List>
                                    <Divider className={classes.divider}/>
                                </div>
                            )
                        }}
                        localization={{
                            toolbar: {
                                exportTitle: intl.formatMessage({id: 'table.export'}),
                                exportAriaLabel: intl.formatMessage({id: 'table.export'}),
                                exportName: intl.formatMessage({id: 'table.exportAsPdf'}),
                            },
                            body: {
                                emptyDataSourceMessage: intl.formatMessage({id: 'table.emptyDataSourceMessage'}),
                                filterRow: {
                                    filterTooltip: intl.formatMessage({id: 'table.filterTooltip'})
                            }
                            },
                            pagination: {
                                labelRowsSelect: intl.formatMessage({id: 'table.rows'}),
                                labelDisplayedRows: intl.formatMessage({id: 'table.page'}),
                                firstTooltip: intl.formatMessage({id: 'table.firstPage'}),
                                previousTooltip: intl.formatMessage({id: 'table.previousPage'}),
                                nextTooltip: intl.formatMessage({id: 'table.nextPage'}),
                                lastTooltip: intl.formatMessage({id: 'table.lastPage'}),
                            }
                        }}
                    />


                    <Card className="mt-5">
                        <CardHeader 
                            title={intl.formatMessage({id: "log.advancedDetails"})}
                        />
                        <List
                            component="ul"
                            classes={{root: classes.root}}
                            >
                                <ListItem classes={{root:classes.listItem }} >
                                    <ListItemText
                                    primary={intl.formatMessage({id: 'log.date'})} 
                                    secondary={logData.loggedAt}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                    />
                                </ListItem>
                                
                                <ListItem classes={{root:classes.listItem }} >
                                    <ListItemText
                                    primary={intl.formatMessage({id: 'log.controller'})} 
                                    secondary={logData.controller}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                    />
                                </ListItem>

                                <ListItem classes={{root:classes.listItem }} >
                                    <ListItemText
                                    primary={intl.formatMessage({id: 'log.fileName'})} 
                                    secondary={logData.fileName}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                    />
                                </ListItem>

                                <ListItem classes={{root:classes.listItem }} >
                                    <ListItemText
                                    primary={intl.formatMessage({id: 'log.userId'})} 
                                    secondary={logData.userId}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                    />
                                </ListItem>

                                <ListItem classes={{root:classes.listItem }} >
                                    <ListItemText
                                    primary={intl.formatMessage({id: 'log.customMethodName'})} 
                                    secondary={logData.customMethodName}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                    />
                                </ListItem>

                            </List>

                    </Card>
                </React.Fragment>
                }
            </div>
        );
    }
}

export default withRouter(injectIntl(withStyles(styles)(LogDetails)));
