import React  from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from "react-intl";

export default function ProgressWithAbort(props){
    const {onAbort} = props;
    return(
        <div className="loader-view">
            <CircularProgress />
            {onAbort && <Button className="mt-5" variant="contained" color="secondary" onClick={onAbort}>
                <FormattedMessage id= "progress.abort" />
            </Button>
            }
        </div>
    );
}
