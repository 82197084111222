import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
  hideMessage,
  showAuthLoader,
  unblockUser,
  resetAuthState
} from 'actions/Auth';

class UnblockUser extends React.Component {
  constructor() {
    super();
    this.state = {
    }
  }

  componentDidMount() {
    const { unblockId } = this.props.match.params;

    this.props.showAuthLoader();
    this.props.unblockUser(unblockId);

  }

  componentDidUpdate() {
    // if (this.props.showMessage) {
    //   setTimeout(() => {
    //     this.props.hideMessage();
    //   }, 3000);
    // }
    // if (this.props.authUser !== null) {
    //   this.props.history.push('/');
    // }
  }


  render() {
    const { history, showMessage, loader, alertMessage, showUnblockedSuccess } = this.props;


    return (
      <div
        className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        <div className="app-login-main-content">
          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Hamilton">
              <img src={require("assets/images/main/hamilton-white.png")}  alt="Hamilton" title="Hamilton" />
            </Link>
          </div>

          <div className="app-login-content">
            <div className="app-login-header">
              <h1><IntlMessages id="appModule.userUnblock" /></h1>
            </div>

            { showMessage && <div class="alert alert-danger" role="alert"><IntlMessages id={alertMessage} /></div> }

            {showUnblockedSuccess && <IntlMessages id="unblock.unblockedSuccessMessage" /> }
            
            <Button variant="contained" className="ml-2" color="primary" onClick={() => {history.push("/signin");}} >
              <IntlMessages id="appModule.signIn" />
            </Button>
          </div>

        </div>

        {
          loader &&
          <div className="loader-view">
            <CircularProgress />
          </div>
        }
        {/* {showMessage && NotificationManager.error(<IntlMessages id={alertMessage} />)} */}
        {/* <NotificationContainer /> */}
      </div>
    )
  }
}

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser, showUnblockedSuccess } = auth;
  return { loader, alertMessage, showMessage, authUser, showUnblockedSuccess }
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  unblockUser,
  resetAuthState
})(UnblockUser);
