import React from "react";
import classNames from "classnames";
import { LinearProgress, withStyles } from "@material-ui/core";

const styles = theme => ({
    container: {
        position: "absolute",
        display: "none",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.4)",
        zIndex: 1100
    },
    show: {
        display: "block"
    }
});

const Loading = ({ show, classes }) => (
    <div
        className={classNames(classes.container, {
            [classes.show]: show
        })}>
        <LinearProgress />
    </div>
);

export default withStyles(styles)(Loading);
