import React, { Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { FormHelperText } from '@material-ui/core';

export default class UserInfoForm extends React.Component {
  constructor() {
    super();
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      emailError: false,
      firstNameError: false,
      lastNameError: false,
      emailErrorText: '',
      firstNameErrorText: '',
      lastNameErrorText: '',
    

      formValid: true,
      formError: ''
    }
  }


  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }


  render() {
    const {
      firstName,
      lastName,
      email
    } = this.state;
    return (
      <Fragment>

        <TextField
              type="email"
              required={true}
              error={this.state.emailError}
              helperText={this.state.emailErrorText}
              onChange={(event) => {
                //validate
                let error=false; 
                let errorText="";
                if(!this.validateEmail(event.target.value)){
                  error = true;
                  errorText = "Please enter a valid email."
                } 

                this.setState({ 
                  email: event.target.value,
                  emailError: error,
                  emailErrorText: errorText 
                  })
                }}
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              defaultValue={email}
              margin="normal"
              className="mt-0 mb-2"
            />

            <TextField
              type="text"
              required={true}
              error={this.state.firstNameError}
              helperText={this.state.firstNameErrorText}
              label={<IntlMessages id="appModule.firstName" />}
              onChange={(event) => this.setState({ firstName: event.target.value })}
              fullWidth
              defaultValue={firstName}
              margin="normal"
              className="mt-0 mb-2"
            />

            <TextField
              type="text"
              required={true}
              error={this.state.lastNameError}
              helperText={this.state.lastNameErrorText}
              label={<IntlMessages id="appModule.lastName" />}
              onChange={(event) => this.setState({ lastName: event.target.value })}
              fullWidth
              defaultValue={lastName}
              margin="normal"
              className="mt-0 mb-2"
            />



                </Fragment>
    )
  }
}



