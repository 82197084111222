import React, { forwardRef } from "react";
import { injectIntl } from "react-intl";
import ContainerHeader from "components/ContainerHeader/index";
import { withRouter } from 'react-router-dom';
import SaveAlt from '@material-ui/icons/SaveAlt';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import { tableIcons } from '../../../../../constants/TableConstants'
import MaterialTable from 'material-table'
import ArrowDownward from '@material-ui/icons/ArrowDownward';
// import { LOGS } from "./LogsDummyData";
import apiService from "../../../../../service/apiService";
import CustomFilterRow from '../CustomFilterRow'
import Chip from '@material-ui/core/Chip';
import Button from '@material-ui/core/Button';
import { FormattedMessage } from "react-intl";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Moment from "react-moment";
import makeStyles from "@material-ui/core/styles/makeStyles";
import withStyles from "@material-ui/core/styles/withStyles";

const axios = require('axios');
const CryptoJS = require("crypto-js");







const options = {
    filtering: true,
    search: false,
    exportButton: false,
    pageSize: 10,
    actionsColumnIndex: -1,
    draggable: false
};

const useStyles = makeStyles({
    tableSortLabel: props => ({
        backgroundColor: "blue",
        "&:hover": {
            backgroundColor: "blue"
        }
    })
});



class Logs extends React.Component {
    state = {
        loading: false,
        rows: [],
        totalCount: 0,
        dialogOpen: false,
        dialogType: "",
        selected: null,
        changeRequiredToggle: false,
        topics: [],
        verifyFileName: null,
        verifyDialogOpen: false,
        verifySuccess: false,
        dialogText: null,
        verifyLoading: false
    };


    fetchData = query => new Promise((resolve, reject) => {
        // console.log(this.props);
        console.log("QUERY");
        console.log(query);

        let deviceKey = this.props.match.params.deviceKey;
        let url = apiService.BASE_URL + "devices/" + deviceKey + "/logs";

        let token = localStorage.getItem("token");
        let config = {
            params: {
                search: query.search,
                size: query.pageSize,
                page: query.page,
                sort: query.orderBy ? `${query.orderBy.field},${query.orderDirection}` : '',
            },
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }

        query.filters.forEach((filt, index, array) => {
            if (filt.column.field !== 'loggedAt')
                config.params[filt.column.field] = filt.value;
            else {
                //Add date filter
                if (filt.value[0] !== undefined) {
                    if (filt.value[0] instanceof Date && !isNaN(filt.value[0]))
                        config.params["from"] = filt.value[0];
                    else return null;
                }
                if (filt.value[1] !== undefined) {
                    if (filt.value[1] instanceof Date && !isNaN(filt.value[1])) {
                        config.params["to"] = filt.value[1];
                    } else return null;
                }
            }
        })

        axios.get(url, config)
            .then(result => {
                console.log("RESULT")
                console.log(result.data)

                if (result.status === 200) {
                    resolve({
                        data: result.data.content,
                        page: result.data.pageable.pageNumber, // current page number
                        totalCount: result.data.totalElements,  // total row number
                    })
                } else {
                    reject({ responseStatus: result.status })
                }
            })
    })

    apiChecksumCheck = fileChecksum => {
        //Make request to API to verify checksum
        axios.get(apiService.BASE_URL + 'devices/logs/file/checksum', {
            params: { checksum: fileChecksum },
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem("token") }
        })
            .then(result => {
                // console.log(result);
                if (result.data === true) {
                    this.setState({
                        verifyDialogOpen: true,
                        verifySuccess: true,
                        verifyLoading: false,
                        dialogText: <FormattedMessage id="log.fileIsValid" />
                    })
                }
                else {
                    this.setState({
                        verifyDialogOpen: true,
                        verifySuccess: false,
                        verifyLoading: false,
                        dialogText: <FormattedMessage id="log.fileIsNotValid" />
                    })
                }
            })
            .catch(err => err)

    }

    onVerifyChange = e => {
        if (e.target.files.length === 0) return;
        const selectedFile = e.target.files[0];
        // console.log(selectedFile)
        this.setState({
            verifyFileName: selectedFile.name,
            verifyLoading: true
        }, () => {
            //read file and compute MD5 checksum
            const apiCheck = this.apiChecksumCheck;
            let reader = new FileReader();
            reader.readAsArrayBuffer(selectedFile);
            reader.onloadend = () => {
                let wordArray = CryptoJS.lib.WordArray.create(reader.result);
                let fileChecksum = CryptoJS.MD5(wordArray).toString();
                // console.log("MD5 Checksum "+ fileChecksum);

                apiCheck(fileChecksum);
            }
        })
    }

    handleDialogClose = () => {
        this.setState({
            verifyFileName: null,
            verifyDialogOpen: false,
            verifySuccess: false,
            verifyLoading: false
        })
    }

    handleExportPdf = logData => {
        const logId = logData.id;

        let token = localStorage.getItem("token");
        axios.get(`${apiService.BASE_URL}devices/logs/${logId}/download/pdf`, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + token,
                'Accept': 'application/pdf'
            }
        })
            .then(res => {
                // Download the file
                const blob = res.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', logData.fileName + '.pdf');
                document.body.appendChild(link);
                link.click();

            })
            .catch(err => err)

    }

    render() {
        const { intl, history } = this.props;
        // const deviceID = match.params.deviceKey;

        return (
            <div style={{ maxWidth: '100%' }}>
                <ContainerHeader match={{ path: history.location.pathname, url: history.location.pathname }} title={intl.formatMessage({ id: 'log.logFiles' })} />
                <Dialog
                    open={this.state.verifyDialogOpen}
                    onClose={this.handleDialogClose}
                >
                    <DialogTitle id="verify-dialog-title">{this.state.verifyFileName}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.dialogText}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary" autoFocus>
                            <FormattedMessage id="dialog.ok" />
                        </Button>
                    </DialogActions>
                </Dialog>

                <MaterialTable
                    title={""}
                    columns={[
                        {
                            field: 'loggedAt',
                            title: intl.formatMessage({ id: 'log.date' }),
                            type: "datetime-range",
                            defaultSort: "desc",
                            headerStyle: useStyles,
                            render: (data) => {
                                const dateToFormat = new Date(data.loggedAt);
                                return <Moment format="YYYY/MM/DD  HH:mm:ss " date={dateToFormat} />;
                            }
                        },
                        {
                            field: "controller",
                            title: intl.formatMessage({ id: 'log.controller' }),
                            type: "text",
                            filtering: false,
                        },
                        {
                            field: "fileName",
                            title: intl.formatMessage({ id: 'log.fileName' }),
                            type: "text"
                        },
                        {
                            field: "customMethodName",
                            title: intl.formatMessage({ id: 'log.customMethodName' }),
                            type: "text"
                        },
                        {/* {
                            field: "userId",
                            title: intl.formatMessage({id: 'log.userId'}),
                            type: "text"
                        }, */}
                    ]}
                    data={this.fetchData}
                    icons={tableIcons}
                    options={options}
                    actions={[
                        {
                            icon: SaveAlt,
                            tooltip: intl.formatMessage({ id: 'table.exportAsPdf' }),
                            onClick: (event, rowData) => {
                                this.handleExportPdf(rowData);
                            }
                        }
                    ]}
                    onRowClick={(event, rowData, togglePanel) => {
                        history.push(history.location.pathname + '/' + rowData.id, rowData)
                    }}
                    components={{
                        FilterRow: CustomFilterRow,
                        Toolbar: props => (
                            <div>
                                {/* <MTableToolbar {...props} /> */}
                                <div style={{ padding: '10px' }}>
                                    <input
                                        accept="application/pdf"
                                        style={{ display: 'none' }}
                                        id="verify-file-button"
                                        type="file"
                                        onChange={this.onVerifyChange}
                                    />

                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <label htmlFor="verify-file-button">
                                            <Chip
                                                icon={<VerifiedUserIcon />}
                                                label={intl.formatMessage({ id: 'table.verifyProtocol' })}
                                                clickable
                                                color="primary"
                                                style={{ marginRight: '5px' }}
                                            />
                                        </label>
                                        {this.state.verifyLoading && <CircularProgress size={24} />}
                                    </div>

                                </div>
                            </div>
                        ),
                    }}
                    localization={{
                        header: {
                            actions: intl.formatMessage({ id: 'table.actions' })
                        },
                        body: {
                            emptyDataSourceMessage: intl.formatMessage({ id: 'table.emptyDataSourceMessage' }),
                            filterRow: {
                                dateTimePickerLocalization: intl.locale,
                                filterTooltip: intl.formatMessage({ id: 'table.filterTooltip' }),
                            }
                        },
                        pagination: {
                            labelRowsSelect: intl.formatMessage({ id: 'table.rows' }),
                            labelDisplayedRows: intl.formatMessage({ id: 'table.page' }),
                            firstTooltip: intl.formatMessage({ id: 'table.firstPage' }),
                            previousTooltip: intl.formatMessage({ id: 'table.previousPage' }),
                            nextTooltip: intl.formatMessage({ id: 'table.nextPage' }),
                            lastTooltip: intl.formatMessage({ id: 'table.lastPage' }),
                        }
                    }}

                />

            </div>
        );
    }
}

export default withRouter(injectIntl(Logs));
