import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReCAPTCHA from "react-google-recaptcha";
import Checkbox from '@material-ui/core/Checkbox';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { hideMessage, resetAuthState, showAuthLoader, userSignUp } from 'actions/Auth';
import { Box } from "@material-ui/core";
import Footer from "../components/Footer";
import { getCurrentAppMode, appModes, reCaptchaKey } from "../service/apiService";

class SignUp extends React.Component {
    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            password: '',
            confirmPassword: '',

            formValid: true,
            formError: '',
            isChecked: false,
            isHuman: false
        }

    }

    componentDidMount() {
        this.props.resetAuthState();
    }

    componentDidUpdate() {
        // if (this.props.showMessage) {
        //   setTimeout(() => {
        //     this.props.hideMessage();
        //   }, 3000);
        // }
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }

    validateForm = () => {
        let msgId, res = true;
        if (this.state.firstName === '' || this.state.lastName === '' || this.state.email === ''
            || this.state.password === '' || this.state.confirmPassword === '') {
            msgId = 'validation.enterAllFields';
            res = false;
        } else if (this.state.password.length < 6) {
            msgId = 'validation.pswTooShort';
            res = false;
        } else if (this.state.password !== this.state.confirmPassword) {
            msgId = 'validation.pswDoNotMatch';
            res = false;
        } else if (!this.state.isHuman && this.isNotOfflineMode()) {
            msgId = 'validation.captcha';
            res = false;
        } else if (!this.state.isChecked && this.isNotOfflineMode()) {
            msgId = 'validation.privacyPolicy';
            res = false;
        }

        return {
            errorMessage: msgId,
            passed: res
        }
    }

  onChange() {
      this.setState({isHuman: !this.state.isHuman})
  }

    isNotOfflineMode() {
      return getCurrentAppMode() !== appModes.offline;
    };

    render() {
        const {
            firstName,
            lastName,
            email,
            password,
            confirmPassword,
            formValid,
            isChecked,
            isHuman
        } = this.state;
        const { showMessage, loader, alertMessage, showSuccess, history } = this.props;

        return (
            <div className="app-main">
                <main className="app-main-content-wrapper">
                    <div className="app-main-content d-flex justify-content-center align-items-center">
                        <div
                            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                            <div className="app-login-main-content">
                                <div className="app-logo-content d-flex align-items-center justify-content-center">
                                    <Link className="logo-lg" to="/" title="Hamilton">
                                        <img src={require("assets/images/main/hamilton-white.png")} alt="Hamilton"
                                            title="Hamilton" />
                                    </Link>
                                </div>

                                <div className="app-login-content">
                                    <div className="app-login-header">
                                        <h1><IntlMessages id="appModule.signUp" /></h1>
                                    </div>

                                    {!showSuccess && <div>
                                        <div className="mb-4">
                                            <h2><IntlMessages id="signUp.createAccount" /></h2>
                                        </div>
                                        {showMessage &&
                                            <div class="alert alert-danger" role="alert"><IntlMessages id={alertMessage} />
                                            </div>}
                                        {!this.state.formValid && <div class="alert alert-danger" role="alert">
                                            <IntlMessages id={this.state.formError} />
                                        </div>
                                        }

                                        <div className="app-login-form">
                                            <form method="post" action="/">

                                                <TextField
                                                    type="text"
                                                    label={<IntlMessages id="user.firstName" />}
                                                    onChange={(event) => this.setState({ firstName: event.target.value })}
                                                    fullWidth
                                                    defaultValue={firstName}
                                                    margin="normal"
                                                    className="mt-0 mb-2"
                                                />

                                                <TextField
                                                    type="text"
                                                    label={<IntlMessages id="user.lastName" />}
                                                    onChange={(event) => this.setState({ lastName: event.target.value })}
                                                    fullWidth
                                                    defaultValue={lastName}
                                                    margin="normal"
                                                    className="mt-0 mb-2"
                                                />

                                                <TextField
                                                    type="email"
                                                    onChange={(event) => this.setState({ email: event.target.value })}
                                                    label={<IntlMessages id="user.email" />}
                                                    fullWidth
                                                    defaultValue={email}
                                                    margin="normal"
                                                    className="mt-0 mb-2"
                                                />

                                                <TextField
                                                    type="password"
                                                    onChange={(event) => this.setState({ password: event.target.value })}
                                                    label={<IntlMessages id="user.password" />}
                                                    fullWidth
                                                    defaultValue={password}
                                                    margin="normal"
                                                    className="mt-0 mb-2"
                                                />

                                                <TextField
                                                    type="password"
                                                    onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                                                    label={getCurrentAppMode() == appModes.offline ?
                                                        <IntlMessages id="signUp.confirmPassword" /> :
                                                        <IntlMessages id="signUp.confirmPassword" />}
                                                    fullWidth
                                                    defaultValue={confirmPassword}
                                                    margin="normal"
                                                    className="mt-0 mb-4"
                                                />

                                                {
                                                    this.isNotOfflineMode() &&
                                                        <>
                                                            <ReCAPTCHA
                                                                className="mb-4"
                                                                sitekey={ reCaptchaKey }
                                                                onChange={() => this.onChange()}
                                                            />

                                                            <div className="mb-4">
                                                                <Checkbox
                                                                    checked={isChecked}
                                                                    color="primary"
                                                                    onChange={(e) => this.setState({isChecked: !isChecked})}
                                                                />
                                                                I have read and agree to the
                                                                &nbsp;<a href="https://www.hamiltoncompany.com/privacy-policy" target="_blank">Privacy Policy</a>
                                                            </div>
                                                        </>
                                                }

                                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                                    <Button variant="contained" onClick={() => {
                                                        let validation = this.validateForm();
                                                        this.setState({
                                                            formValid: validation.passed,
                                                            formError: validation.errorMessage
                                                        }, () => {
                                                            if (formValid) {
                                                                this.props.showAuthLoader();
                                                                this.props.userSignUp({
                                                                    firstName,
                                                                    lastName,
                                                                    email,
                                                                    password
                                                                });
                                                            }
                                                        });
                                                    }} color="primary">
                                                        <IntlMessages id="signUp.register" />
                                                    </Button>
                                                    <Link to="/signin">
                                                        <IntlMessages id="signUp.alreadyMember" />
                                                    </Link>
                                                </div>
                                            </form>

                                        </div>
                                    </div>}
                                    {showSuccess &&
                                        <div>
                                            {
                                                getCurrentAppMode() == appModes.offline ?
                                                    <IntlMessages id="activation.activatedSuccessMessage" /> :
                                                    <IntlMessages id="signUp.successMessage" />
                                            }

                                            <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                                                <Box p={1}>
                                                    <Button variant="contained" className="ml-2" color="primary"
                                                        onClick={() => {
                                                            history.push("/signin");
                                                        }}>
                                                        <IntlMessages id="appModule.signIn" />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </div>}
                                </div>

                            </div>

                            {
                                loader &&
                                <div className="loader-view">
                                    <CircularProgress />
                                </div>
                            }
                            {/* {showMessage && NotificationManager.error(<IntlMessages id={alertMessage} />)} */}
                            {/* <NotificationContainer /> */}
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser, showSuccess } = auth;
    return { loader, alertMessage, showMessage, authUser, showSuccess }
};

export default connect(mapStateToProps, {
    userSignUp,
    hideMessage,
    showAuthLoader,
    resetAuthState
})(SignUp);
