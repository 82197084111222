import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
    hideMessage,
    showAuthLoader,
    hideAuthLoader
} from 'actions/Auth';
import apiService from '../service/apiService';
const axios = require('axios');


class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            password: '',
            confirmPassword: '',
            errorId: null,
            showResetSuccess: false
        }
    }

    componentDidUpdate() {
        // if (this.props.showMessage) {
        //   setTimeout(() => {
        //     this.props.hideMessage();
        //   }, 3000);
        // }
        if (this.props.authUser !== null) {
          this.props.history.push('/');
        }
    }

    validatePassword(){
        let msgId;
        if(this.state.password==='' || this.state.confirmPassword===''){
            msgId = 'validation.enterAllFields';
        }
        else if(this.state.password.length<6){
            msgId = 'validation.pswTooShort'; 
          }
        else if(this.state.password !== this.state.confirmPassword){
            msgId = 'validation.pswDoNotMatch';
        }
        return {
            message: msgId,
        }
    }


    resetPasswordRequest = (password, resetId) => {
        axios.post(`${apiService.BASE_URL}api/forgotpassword/${resetId}`, password,
        {
          headers: {
              'Content-Type': 'application/json',
              'Accept': 'application/json'   
          }
        }
        )
        .then(res=>{
            this.props.hideAuthLoader();
            this.setState({showResetSuccess: true, errorId: null})
    
        })
        .catch(err=>{
          this.props.hideAuthLoader();
          this.setState({errorId: err.response.data.description});    
        });
    }

    render() {
        const {
            showResetSuccess,

        } = this.state;
        const { history, match,  loader } = this.props;
        const resetId = match.params.resetId;

        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center">
                        <Link className="logo-lg" to="/" title="Hamilton">
                            <img src={require("assets/images/main/hamilton-white.png")} alt="Hamilton" title="Hamilton" />
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header">
                            <h1><IntlMessages id="appModule.resetPassword" /></h1>
                        </div>


                        { showResetSuccess ? (
                            <div>
                            <IntlMessages id="resetPassword.resetSuccessMessage" />
                                <Button variant="contained" className="ml-2" color="primary" onClick={() => {history.push("/signin");}} >
                                    <IntlMessages id="appModule.signIn" />
                                </Button>
                            </div>
                        ):(<div className="app-login-form">
                            <form method="post" action="/">
                                { this.state.errorId && <div class="alert alert-danger" role="alert">
                                  <IntlMessages id={this.state.errorId} />
                                    </div>
                                }
                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({ password: event.target.value })}
                                    label={<IntlMessages id="signUp.password" />}
                                    fullWidth
                                    defaultValue={this.state.password}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="password"
                                    onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                                    label={<IntlMessages id="signUp.confirmPassword" />}
                                    fullWidth
                                    defaultValue={this.state.confirmPassword}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                                        

                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    <Button variant="contained" onClick={() => {
                                        let validation = this.validatePassword();
                                        this.setState({
                                            errorId: validation.message
                                        }, ()=>{
                                            if(!this.state.errorId){
                                                this.props.showAuthLoader();
                                                this.resetPasswordRequest(this.state.password, resetId);
                                            }
                                        });
                                    }} color="primary">
                                        <IntlMessages
                                            id="forgetPassword.send" />
                                    </Button>
                                    <Link to="/signin">
                                        <IntlMessages id="appModule.signIn" />
                                    </Link>
                                </div>
                            </form>
                        </div>
                        )}
                    </div>

                </div>

                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress />
                    </div>
                }
                {/* {showMessage && NotificationManager.error(<IntlMessages id={alertMessage} />)} */}
                {/* <NotificationContainer /> */}
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser } = auth;
    return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
    hideMessage,
    showAuthLoader,
    hideAuthLoader
})(ResetPassword);
