import React from "react";
import { injectIntl } from "react-intl";
import {withRouter} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { tableIcons } from '../../../../../constants/TableConstants'
import Moment from "react-moment";
import MaterialTable, {MTableToolbar} from "material-table";
import Typography from "@material-ui/core/Typography";
import MuiLink from "@material-ui/core/Link";
const axios = require('axios');




// table's container style
const style = {
}

const styles = {
    formControl: {
        // minWidth: 120,
        // backgroundColor: 'rgb(255,0,0)',
    },
    list: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
        // maxWidth: '100%',
    },
    listItem: {
        width: 'fit-content'
    },
    listHeader: {
        backgroundColor: '#a6a6a6',
    },
    notClickableItem: {
        backgroundColor: '#ededed'
    },
    itemPrimary: {
        fontWeight: 'bold',
        fontSize: '1em'
    },
    itemSecondary: {
        fontWeight: 'normal',
        fontSize: '1.2em'
    },
    inputLabel: {
        fontWeight: 'bold',
        fontSize: '1.4em',
        color: '#525252',
    },
    divider: {
        // border: 'none',
        // height: '10px',
        // backgroundColor: '#525252',
        // color: '#525252',
    },

}

class CustomMethodDetail extends React.Component {
    state = {
        rows: [],
        totalCount: 0,
        dialogOpen: false,
        dialogType: "",
        selected: null,
        changeRequiredToggle: false,
        topics: [],
    };

    componentDidMount(){
        //if did not navigate here though Log Files page (for example pasting address) -> redirect
        if(this.props.history.location.state===undefined) {
            this.props.history.push('/app/main/devices')
        }
    }




    render() {
        const { intl, history, classes } = this.props;
        const customMethod =  history.location.state;
        const {stepList} = customMethod;
        console.log(stepList);

        return (
            <div style={{ maxWidth: '100%' }}>

                {stepList &&  <React.Fragment>
                    <MaterialTable
                        title={intl.formatMessage({id: 'custom-method.detail.title'})}
                        style={style}
                        columns={[
                            {
                                field: "trigger",
                                title: intl.formatMessage({id: 'custom-method.detail.trigger'}),
                                type: "text",
                                lookup : {1:"Probe", 2:"Auto", 3:"TTL"},
                                emptyValue:"UNKNOWN"
                            },
                            {
                                field: "valvePositionLeft",
                                title: intl.formatMessage({id: 'custom-method.detail.left.valve.position'}),
                                lookup: {10: intl.formatMessage({id: 'valve.out'}), 9: intl.formatMessage({id: 'valve.in'})},
                                emptyValue:"UNKNOWN"
                            },
                            {
                                field: "volumeLeftInUL",
                                title: intl.formatMessage({id: 'custom-method.detail.left.syringe.volume'}),
                                type: "text"
                            },
                            {
                                field: "valvePositionRight",
                                title: intl.formatMessage({id: 'custom-method.detail.right.valve.position'}),
                                lookup: {10: intl.formatMessage({id: 'valve.out'}), 9: intl.formatMessage({id: 'valve.in'})},
                                emptyValue:"UNKNOWN"
                            },
                            {
                                field: "volumeRightInUL",
                                title: intl.formatMessage({id: 'custom-method.detail.right.syringe.volume'}),
                                type: "text"
                            },
                            {
                                field: "loopCounterStart",
                                title: intl.formatMessage({id: 'custom-method.detail.loop-counter.start'}),
                                type: "text"
                            },
                            {
                                field: "loopCounterFinish",
                                title: intl.formatMessage({id: 'custom-method.detail.loop-counter.finish'}),
                                type: "text"
                            },
                            {
                                field: "details",
                                title: intl.formatMessage({id: 'custom-method.detail.details'}),
                                filtering: false,
                                render: () => {
                                    let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(customMethod, null, "\t"));
                                    let fileName = customMethod.dateCreated;
                                    return (<Typography>
                                        <MuiLink href={"data:"+data} download={`${fileName}.json`} underline='always'>JSON</MuiLink>
                                    </Typography>)
                                }
                            }
                        ]}
                        data={stepList}
                        icons={tableIcons}
                        options={{
                            filtering: false,
                            search: false,
                            exportButton: false,
                            paging: false,
                            sorting:false,
                            draggable: false,
                            rowStyle: {
                            },
                            headerStyle: {
                            }
                        }}
                        components={{
                            Toolbar: props => ( <MTableToolbar {...props} />)
                        }}
                        localization={{
                            toolbar: {
                                exportTitle: intl.formatMessage({id: 'table.export'}),
                                exportAriaLabel: intl.formatMessage({id: 'table.export'}),
                                exportName: intl.formatMessage({id: 'table.exportAsPdf'}),
                            },
                            body: {
                                emptyDataSourceMessage: intl.formatMessage({id: 'table.emptyDataSourceMessage'}),
                                filterRow: {
                                    filterTooltip: intl.formatMessage({id: 'table.filterTooltip'})
                                }
                            },
                            pagination: {
                                labelRowsSelect: intl.formatMessage({id: 'table.rows'}),
                                labelDisplayedRows: intl.formatMessage({id: 'table.page'}),
                                firstTooltip: intl.formatMessage({id: 'table.firstPage'}),
                                previousTooltip: intl.formatMessage({id: 'table.previousPage'}),
                                nextTooltip: intl.formatMessage({id: 'table.nextPage'}),
                                lastTooltip: intl.formatMessage({id: 'table.lastPage'}),
                            }
                        }}
                    />
                </React.Fragment>
                }
            </div>
        );
    }
}

export default withRouter(injectIntl(withStyles(styles)(CustomMethodDetail)));
