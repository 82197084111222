import React from "react";
import { injectIntl } from "react-intl";
import ContainerHeader from "components/ContainerHeader/index";
import {withRouter} from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { tableIcons } from '../../../../../constants/TableConstants'
import MaterialTable, { MTableToolbar} from 'material-table'
import apiService from "../../../../../service/apiService";
import Moment from "react-moment";
const axios = require('axios');




// table's container style
const style = {
}

const styles = {
    formControl: {
        // minWidth: 120,
        // backgroundColor: 'rgb(255,0,0)',
    },
    list: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap'
        // maxWidth: '100%',
    },
    listItem: {
        width: 'fit-content'
    },
    listHeader: {
        backgroundColor: '#a6a6a6',
    },
    notClickableItem: {
        backgroundColor: '#ededed'
    },
    itemPrimary: {
        fontWeight: 'bold',
        fontSize: '1em'
    },
    itemSecondary: {
        fontWeight: 'normal',
        fontSize: '1.2em'
    },
    inputLabel: {
        fontWeight: 'bold',
        fontSize: '1.4em',
        color: '#525252',
    },
    divider: {
        // border: 'none',
        // height: '10px',
        // backgroundColor: '#525252',
        // color: '#525252',
    },

}

class UserDevices extends React.Component {
    state = {
        rows: [],
        totalCount: 0,
        dialogOpen: false,
        dialogType: "",
        selected: null,
        changeRequiredToggle: false,
        topics: [],
    };

    componentDidMount(){
        //if did not navigate here though Log Files page (for example pasting address) -> redirect
        if(this.props.history.location.state===undefined) {
            this.props.history.push('/app/main/users')
        }
        this.setState({...this.props.location.state})
        const userId = this.props.match.params.id;
        let url = apiService.BASE_URL + `users/${userId}/devices`;
        let token = localStorage.getItem("token");
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }
        axios.get(url, config)
            .then(result => {
                if (result.status === 200) {
                    this.setState({devices: result.data});
                } else {
                    console.log(result.status);
                }

            })
            .catch(err => err);

    }

    handleExportPdf = logData => {
        const logId = logData.id;
        let token = localStorage.getItem("token");
        axios.get(`${apiService.BASE_URL}devices/logs/${logId}/download/pdf`,{
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer '+ token,
                'Accept': 'application/pdf'
            }
        })
            .then(res => {
                // Download the file
                const blob = res.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', logData.fileName+'.pdf');
                document.body.appendChild(link);
                link.click();

            })
            .catch(err=>err)
    }



    render() {
        const { intl, history, classes } = this.props;
        const logData = history.location.state;

        return (
            <div style={{ maxWidth: '100%' }}>

                {logData &&  <React.Fragment>
                    <ContainerHeader match={{path:history.location.pathname, url: history.location.pathname}} title={logData.fileName} />
                    <MaterialTable
                        title={intl.formatMessage({id: 'log.logFileDetails'})}
                        style={style}
                        columns={[
                        {
                            field: "name",
                            title: intl.formatMessage({id: 'device.name'}),
                        },
                        {
                            field: "type",
                            title: intl.formatMessage({id: 'device.type'}),
                        },
                        // {
                        //     field: "serial",
                        //     title: intl.formatMessage({id: 'device.serial'}),
                        // },
                        {
                            field: "mac",
                            title: intl.formatMessage({id: 'device.mac'}),
                        }
                    ]}
                        data={this.state.devices}
                        icons={tableIcons}
                        options={{
                            filtering: true,
                            search: false,
                            pageSize: 20,
                            draggable: false,
                            rowStyle: {
                            },
                            headerStyle: {
                            },
                            exportButton: true,
                            exportCsv: (columns, data) => {
                                this.handleExportPdf(logData);
                            }
                        }}
                        components={{
                            Toolbar: props => (
                                <div style={{ backgroundColor: '#e8eaf5' }}>
                                    <MTableToolbar {...props} />
                                    <Divider className={classes.divider}/>
                                    <List
                                        component="ul"
                                        classes={{root: classes.list}}
                                    >
                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                                primary={intl.formatMessage({id: 'log.date'})}
                                                secondary={<Moment format="YYYY/MM/DD  HH:mm:ss " date={new Date(logData.loggedAt)}/>}
                                                classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                                primary={intl.formatMessage({id: 'log.controller'})}
                                                secondary={logData.controller}
                                                classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                                primary={intl.formatMessage({id: 'log.fileName'})}
                                                secondary={logData.fileName}
                                                classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                        {/*<ListItem classes={{root:classes.listItem }} >*/}
                                        {/*    <ListItemText*/}
                                        {/*    primary={intl.formatMessage({id: 'log.userId'})} */}
                                        {/*    secondary={logData.userId}*/}
                                        {/*    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}*/}
                                        {/*    />*/}
                                        {/*</ListItem>*/}

                                        <ListItem classes={{root:classes.listItem }} >
                                            <ListItemText
                                                primary={intl.formatMessage({id: 'log.customMethodName'})}
                                                secondary={logData.customMethodName}
                                                classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                            />
                                        </ListItem>

                                    </List>
                                    <Divider className={classes.divider}/>
                                </div>
                            )
                        }}
                        localization={{
                            toolbar: {
                                exportTitle: intl.formatMessage({id: 'table.export'}),
                                exportAriaLabel: intl.formatMessage({id: 'table.export'}),
                                exportName: intl.formatMessage({id: 'table.exportAsPdf'}),
                            },
                            body: {
                                emptyDataSourceMessage: intl.formatMessage({id: 'table.emptyDataSourceMessage'}),
                                filterRow: {
                                    filterTooltip: intl.formatMessage({id: 'table.filterTooltip'})
                                }
                            },
                            pagination: {
                                labelRowsSelect: intl.formatMessage({id: 'table.rows'}),
                                labelDisplayedRows: intl.formatMessage({id: 'table.page'}),
                                firstTooltip: intl.formatMessage({id: 'table.firstPage'}),
                                previousTooltip: intl.formatMessage({id: 'table.previousPage'}),
                                nextTooltip: intl.formatMessage({id: 'table.nextPage'}),
                                lastTooltip: intl.formatMessage({id: 'table.lastPage'}),
                            }
                        }}
                    />


                    <Card className="mt-5">
                        <CardHeader
                            title={intl.formatMessage({id: "log.advancedDetails"})}
                        />
                        <List
                            component="ul"
                            classes={{root: classes.root}}
                        >
                            <ListItem classes={{root:classes.listItem }} >
                                <ListItemText
                                    primary={intl.formatMessage({id: 'log.date'})}
                                    secondary={logData.loggedAt}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                />
                            </ListItem>

                            <ListItem classes={{root:classes.listItem }} >
                                <ListItemText
                                    primary={intl.formatMessage({id: 'log.controller'})}
                                    secondary={logData.controller}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                />
                            </ListItem>

                            <ListItem classes={{root:classes.listItem }} >
                                <ListItemText
                                    primary={intl.formatMessage({id: 'log.fileName'})}
                                    secondary={logData.fileName}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                />
                            </ListItem>

                            <ListItem classes={{root:classes.listItem }} >
                                <ListItemText
                                    primary={intl.formatMessage({id: 'log.userId'})}
                                    secondary={logData.userId}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                />
                            </ListItem>

                            <ListItem classes={{root:classes.listItem }} >
                                <ListItemText
                                    primary={intl.formatMessage({id: 'log.customMethodName'})}
                                    secondary={logData.customMethodName}
                                    classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                                />
                            </ListItem>

                        </List>

                    </Card>
                </React.Fragment>
                }
            </div>
        );
    }
}

export default withRouter(injectIntl(withStyles(styles)(UserDevices)));



// import React from "react";
// import {tableIcons} from '../../../../../constants/TableConstants'
// import MaterialTable from 'material-table'
// import apiService from "../../../../../service/apiService";
// import {injectIntl} from "react-intl";
// import ProgressWithAbort from '../ProgressWithAbort';
// import {withStyles} from "@material-ui/core/styles";
// import {withRouter} from "react-router-dom";
//
// const axios = require('axios');
//
//
// const options = {
//     filtering: false,
//     search: false,
//     sorting: false,
//     exportButton: false,
//     actionsColumnIndex: -1,
//     headerStyle: {
//         //textAlign: "center"
//     },
//     paging: false,
//     draggable: false,
//     actionsCellStyle: {}
// };
//
// const styles = {
//
//     list: {
//         flexGrow: 1,
//         display: 'flex',
//         flexDirection: 'row',
//         flexWrap: 'wrap'
//         // maxWidth: '100%',
//     },
//     listItem: {
//         width: 'fit-content'
//     },
//     listHeader: {
//         backgroundColor: '#a6a6a6',
//     },
// }
//
//
// class UserDevices extends React.Component {
//
//     state = {}
//
//     componentDidMount() {
//         this.setState({...this.props.location.state})
//         const userId = this.props.match.params.id;
//         let url = apiService.BASE_URL + `users/${userId}/devices`;
//         let token = localStorage.getItem("token");
//         let config = {
//             headers: {
//                 'Authorization': 'Bearer ' + token,
//             }
//         }
//         axios.get(url, config)
//             .then(result => {
//                 if (result.status === 200) {
//                     this.setState({devices: result.data});
//                 } else {
//                     console.log(result.status);
//                 }
//
//             })
//             .catch(err => err);
//
//     }
//
//     handleAbort = () => {
//         this.setState({
//             pendingRequest: null,
//             wasSuccessful: false
//         })
//     }
//
//     render() {
//         const {intl, classes} = this.props;
//         return (
//             <div style={{maxWidth: '100%'}}>
//
//
//                 <MaterialTable
//                     title={intl.formatMessage({id: "appModule.devices"})}
//                     columns={[
//                         {
//                             field: "name",
//                             title: intl.formatMessage({id: 'device.name'}),
//                         },
//                         {
//                             field: "type",
//                             title: intl.formatMessage({id: 'device.type'}),
//                         },
//                         {
//                             field: "serial",
//                             title: intl.formatMessage({id: 'device.serial'}),
//                         },
//                         {
//                             field: "mac",
//                             title: intl.formatMessage({id: 'device.mac'}),
//                         }
//                     ]}
//                     isLoading={!this.state.devices}
//                     data={this.state.devices}
//                     icons={tableIcons}
//                     options={options}
//                     actions={[{}]}
//                     // components={{}}
//                     localization={{
//                         header: {
//                             actions: ''
//                         },
//                         toolbar: {
//                             searchTooltip: intl.formatMessage({id: 'table.search'}),
//                             searchPlaceholder: intl.formatMessage({id: 'table.search'})
//                         },
//                         body: {
//                             emptyDataSourceMessage: intl.formatMessage({id: 'table.emptyDataSourceMessage'}),
//                             filterRow: {
//                                 filterTooltip: intl.formatMessage({id: 'table.filterTooltip'})
//                             }
//                         },
//                         pagination: {
//                             labelRowsSelect: intl.formatMessage({id: 'table.rows'}),
//                             labelDisplayedRows: intl.formatMessage({id: 'table.page'}),
//                             firstTooltip: intl.formatMessage({id: 'table.firstPage'}),
//                             previousTooltip: intl.formatMessage({id: 'table.previousPage'}),
//                             nextTooltip: intl.formatMessage({id: 'table.nextPage'}),
//                             lastTooltip: intl.formatMessage({id: 'table.lastPage'}),
//                         }
//                     }}
//                 />
//
//                 {this.state.pendingRequest && <ProgressWithAbort onAbort={this.handleAbort}/>}
//             </div>
//         );
//     }
// }
//
// export default withRouter(injectIntl(withStyles(styles)(UserDevices)));