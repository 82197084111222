import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { FiCheck } from "react-icons/fi";
import { IconButton, FormControl, Input, InputAdornment, TextField } from '@material-ui/core';
import apiService from "../../../../../service/apiService";

const axios = require('axios');

export class DeviceRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            device: this.props.deviceData,
            deviceName: this.props.deviceData.name,
            isEditing: false
        };
    }

    toggleEditingMode = async (enable) => {
        if (enable) {
            this.setState({
                isEditing: enable
            });
        }


        if (!enable) {
            if (this.state.deviceName.length === 0) {
                return;
            }

            this.setState({
                isEditing: enable
            });

            let device = this.state.device;
            device.name = this.state.deviceName;
            await putRequest("devices/updateName", device);
        }
    };

    onDeviceRenameTextChange = (event) => {
        this.setState({
            deviceName: event.target.value
        });
    }

    componentDidMount() {
        this.setState({
            isEditing: false
        });
    }


    render() {
        let device = this.state.device;
        return (
            <div>
                <dl className="inline-flex">
                    <dt><FormattedMessage id='device.name' />:</dt>
                    {this.state.isEditing === true ?
                        <dd>
                            <FormControl>
                                <TextField
                                    error={this.state.deviceName.length == 0}
                                    style={{ fontSize: "14px" }}
                                    type={'text'}
                                    defaultValue={this.state.deviceName}
                                    onChange={this.onDeviceRenameTextChange.bind(this)}
                                    InputProps={{
                                        endAdornment: (<InputAdornment position="end">
                                            <IconButton onClick={this.toggleEditingMode.bind(this, false)}>
                                                <FiCheck fontSize="25px" />
                                            </IconButton>
                                        </InputAdornment>)
                                    }} />
                            </FormControl>
                        </dd> :
                        <dd onClick={this.toggleEditingMode.bind(this, true)}>
                            {this.state.deviceName}
                        </dd>}
                    <dt> <FormattedMessage id='device.type' />:</dt>
                    <dd>{device.type}</dd>

                    <dt><FormattedMessage id='device.mac' />:</dt>
                    <dd>{device.mac}</dd>
                    <dt><FormattedMessage id='device.status' /></dt>
                    <dd style={{
                        color: device.online ? "green" : "red",
                        fontWeight: "bold"
                    }}>{device.online ? <FormattedMessage id='device.connected' /> : <FormattedMessage id='device.disconnected' />}
                    </dd>
                </dl>
            </div>
        );
    }
}


async function getRequest(method) {
    let url = apiService.BASE_URL + method// "devices";
    let token = localStorage.getItem("token");
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }
    try {
        var result = await axios.get(url, config);
        if (result.status === 200) {
            return result.data.content;
        }
    } catch {
        return null;
    }
}

async function putRequest(method, payload) {
    let url = apiService.BASE_URL + method// "devices";
    let token = localStorage.getItem("token");
    let config = {
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        }
    }
    try {
        var result = await axios.put(url, payload, config);
        if (result.status === 200) {
            return result.data.content;
        }
    } catch {
        return null;
    }
}

export default injectIntl(DeviceRow);
