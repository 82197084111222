import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
    withStyles,
    Zoom
} from "@material-ui/core";
import Loading from "../Loading";
import { FormattedMessage } from "react-intl";
import { Receipt } from "@material-ui/icons";
import TextField from '@material-ui/core/TextField';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { FormHelperText } from '@material-ui/core';

const styles = theme => ({
    root: {
        overflowY: "auto"
    },
    container: {
        width: 350,
        overflowY: "visible",
        maxHeight: "none"
    },
    content: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 3,
        paddingRight: theme.spacing.unit * 3,
        overflowY: "visible"
    }
});

class BaseDialog extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            firstName: '',
            lastName: '',
            emailError: false,
            firstNameError: false,
            lastNameError: false,
            emailErrorText: '',
            firstNameErrorText: '',
            lastNameErrorText: '',


            formValid: true,
            formError: ''
        }
    }

    validateEmail = email => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    render() {
        const {
            classes,
            children,
            title,
            onOkClick,
            open,
            onClose,
            type,
            loading,
            onEntering,
            okDisabled,
            rowData
        } = this.props;

        return (
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                classes={{
                    root: classes.root,
                    paper: classes.container
                }}
                open={open}
                onClose={() => onClose()}
                TransitionComponent={Zoom}
                onEntering={onEntering}
            >
                <fieldset disabled={loading}>
                    <DialogTitle>{title}</DialogTitle>
                    <DialogContent className={classes.content}>
                        {type === "new" ? (
                            <form>
                                <TextField
                                    type="email"
                                    required={true}
                                    error={this.state.emailError}
                                    helperText={this.state.emailErrorText}
                                    onChange={(event) => {
                                        //validate
                                        let error = false;
                                        let errorText = "";
                                        if (!this.validateEmail(event.target.value)) {
                                            error = true;
                                            errorText = "Please enter a valid email."
                                        }

                                        this.setState({
                                            email: event.target.value,
                                            emailError: error,
                                            emailErrorText: errorText
                                        })
                                    }}
                                    label={<IntlMessages id="appModule.email" />}
                                    fullWidth
                                    defaultValue={this.state.email}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="text"
                                    required={true}
                                    error={this.state.firstNameError}
                                    helperText={this.state.firstNameErrorText}
                                    label={<IntlMessages id="appModule.firstName" />}
                                    onChange={(event) => this.setState({ firstName: event.target.value })}
                                    fullWidth
                                    defaultValue={this.state.firstName}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />

                                <TextField
                                    type="text"
                                    required={true}
                                    error={this.state.lastNameError}
                                    helperText={this.state.lastNameErrorText}
                                    label={<IntlMessages id="appModule.lastName" />}
                                    onChange={(event) => this.setState({ lastName: event.target.value })}
                                    fullWidth
                                    defaultValue={this.state.lastName}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />
                            </form>
                        ) : type === "delete" ? (
                            <Typography>
                                <FormattedMessage id="dialog.deleteConfirmation" />
                                {rowData.email} ?
                            </Typography>
                        ) : type === "reset" ? (<Typography>
                            <FormattedMessage id="dialog.resetConfirmation" />
                            {rowData.email}
                        </Typography>) : null}
                    </DialogContent>
                    <DialogActions>
                        {type !== "info" && (
                            <Button onClick={() => onClose()} color="primary">
                                <FormattedMessage id="dialog.cancel" />
                            </Button>
                        )}
                        <Button
                            disabled={type !== "delete" && okDisabled}
                            onClick={onOkClick}
                            color="primary"
                        >
                            {type === "new" ? (
                                <FormattedMessage id="dialog.add" />
                            ) : type === "edit" ? (
                                <FormattedMessage id="dialog.save" />
                            ) : type === "delete" ? (
                                <FormattedMessage id="dialog.delete" />
                            ) : type === "reset" ? (
                                <FormattedMessage id="dialog.reset" />
                            ) : <FormattedMessage id="dialog.ok" />
                            }
                        </Button>
                    </DialogActions>
                </fieldset>
                <Loading show={loading} />
            </Dialog>

        )
    }
}

export default withStyles(styles)(BaseDialog);
