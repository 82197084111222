import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';

import asyncComponent from '../../../util/asyncComponent';

import Devices from './routes/devices';
import DeviceAuditTrail from './routes/audittrails/DeviceAuditTrail';
import CloudAuditTrail from './routes/audittrails/CloudAuditTrail';
import Logs from './routes/logs';
import LogDetails from './routes/logs/detail';
import DeviceConfiguration from './routes/configuration';
import UserProfile from './routes/profile';
import CustomMethods from "./routes/methods/CustomMethods";
import Favourites from "./routes/methods/Favourites";
import Users from "./routes/users";
import CustomMethodDetail from "./routes/methods/CustomMethodDetail";
import UserDevices from "./routes/users/UserDevices";


const Components = ({match}) => (
  <div className="app-wrapper">
    <Switch>

      <Redirect exact from={`${match.url}/`} to={`${match.url}/devices`}/>
      <Route  exact path={`${match.url}/users`} component={Users}/>
      <Route path={`${match.url}/users/:id/devices`} component={UserDevices}/>
      <Route path={`${match.url}/devices/:deviceKey/logs/:log_file`} component={LogDetails}/>
      <Route path={`${match.url}/devices/:deviceKey/logs`} component={Logs}/>
      <Route path={`${match.url}/devices/:deviceKey/audittrail`} component={DeviceAuditTrail}/>
      <Route path={`${match.url}/devices/:deviceKey/configuration`} component={DeviceConfiguration}/>
      <Redirect exact from={`${match.url}/devices/:deviceKey`} to={`${match.url}/devices`}/>
      <Route exact path={`${match.url}/devices`} component={Devices}/>
      <Route path={`${match.url}/audittrail`} component={CloudAuditTrail}/>
      <Route exact path={`${match.url}/devices/:deviceKey/custom-methods`} component={CustomMethods}/>
      <Route path={`${match.url}/devices/:deviceKey/custom-methods/:id`} component={CustomMethodDetail}/>
      <Route path={`${match.url}/devices/:deviceKey/favourites`} component={Favourites}/>
      <Route path={`${match.url}/profile`} component={UserProfile}/>
      <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
    </Switch>
  </div>
);

export default Components;
