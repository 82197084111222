import appLocaleData from 'react-intl/locale-data/ja';
import jpMessages from '../locales/ja_JP.json';

const jpLang = {
  messages: {
    ...jpMessages
  },
  locale: 'ja-JP',
  data: appLocaleData
};
export default jpLang;
