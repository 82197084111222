import React from 'react';
import { connect } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import { Box, Button } from '@material-ui/core';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link } from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import { hideAuthLoader, hideMessage, showAuthLoader, showAuthMessage, userForgotPsw } from 'actions/Auth';
import apiService, { appModes, getCurrentAppMode } from '../service/apiService';
import Footer from "../components/Footer";
const axios = require('axios');


class ForgetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            errorId: null,
            showForgetSuccess: false
        }
    }

    componentDidUpdate() {
        // if (this.props.showMessage) {
        //     setTimeout(() => {
        //         this.props.hideMessage();
        //     }, 3000);
        // }
    }


    forgetPasswordRequest = email => {
        axios.post(`${apiService.BASE_URL}api/forgotpassword`, email
            ,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
            }
        )
            .then(res => {
                this.props.hideAuthLoader();
                this.setState({ showForgetSuccess: true, errorId: null });
            })
            .catch(err => {
                this.props.hideAuthLoader();

                this.setState({ errorId: err.response.data.description });
            });
    }

    render() {
        const {
            email,
            showForgetSuccess
        } = this.state;
        const { loader, history } = this.props;


        return (
            <div className="app-main">
                <main className="app-main-content-wrapper">
                    <div className="app-main-content d-flex justify-content-center align-items-center">
                        <div
                            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                            <div className="app-login-main-content">
                                <div className="app-logo-content d-flex align-items-center justify-content-center">
                                    <Link className="logo-lg" to="/" title="Hamilton">
                                        <img src={require("assets/images/main/hamilton-white.png")} alt="Hamilton"
                                            title="Hamilton" />
                                    </Link>
                                </div>

                                <div className="app-login-content">
                                    <div className="app-login-header">
                                        <h1><IntlMessages id="appModule.forgotPassword" /></h1>
                                    </div>
                                    {this.state.errorId && <div class="alert alert-danger" role="alert">
                                        <IntlMessages id={this.state.errorId} />
                                    </div>
                                    }

                                    {showForgetSuccess ? (
                                        <div>
                                            {
                                                getCurrentAppMode() == appModes.offline ?
                                                    <IntlMessages id="resetPassword.resetAdminSuccessMessage" /> :
                                                    <IntlMessages id="forgetPassword.resetLinkSent" />
                                            }
                                            <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                                                <Box p={1}>
                                                    <Button variant="contained" className="ml-2" color="primary"
                                                        onClick={() => {
                                                            history.push("/signin");
                                                        }}>
                                                        <IntlMessages id="appModule.signIn" />
                                                    </Button>
                                                </Box>
                                            </Box>
                                        </div>
                                    ) : (<div className="app-login-form">
                                        <form method="post" action="/">
                                            <TextField
                                                type="email"
                                                onChange={(event) => this.setState({ email: event.target.value })}
                                                label={
                                                    getCurrentAppMode() == appModes.offline ?
                                                        <IntlMessages id="forgetPassword.servicePassword" /> :
                                                        <IntlMessages id="user.email" />
                                                }
                                                fullWidth
                                                defaultValue={email}
                                                margin="normal"
                                                className="mt-0 mb-4"
                                                error={this.state.errorId}
                                            />


                                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                                <Button variant="contained" onClick={() => {
                                                    let errId = null;
                                                    if (!email || email.length === 0) errId = 'validation.enterAllFields';
                                                    this.setState({ errorId: errId }, () => {
                                                        if (!this.state.errorId) {
                                                            this.props.showAuthLoader();
                                                            this.forgetPasswordRequest(email);
                                                        }
                                                    })
                                                }} color="primary">
                                                    <IntlMessages
                                                        id="forgetPassword.send" />
                                                </Button>
                                                <Link to="/signin">
                                                    <IntlMessages id="appModule.signIn" />
                                                </Link>
                                            </div>
                                        </form>
                                    </div>
                                        )}
                                </div>

                            </div>

                            {loader &&
                                <div className="loader-view">
                                    <CircularProgress />
                                </div>
                            }
                            {/* {showMessage && NotificationManager.error(alertMessage)} */}
                            {/* <NotificationContainer /> */}
                        </div>
                    </div>
                    <Footer />
                </main>
            </div>
        )
    }
}

const mapStateToProps = ({ auth }) => {
    const { loader, alertMessage, showMessage, authUser } = auth;
    return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
    userForgotPsw: userForgotPsw,
    showAuthMessage,
    hideMessage,
    showAuthLoader,
    hideAuthLoader
})(ForgetPassword);
