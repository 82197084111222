import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  ACTIVATE_USER_SUCCESS,
  UNBLOCK_USER_SUCCESS,
  RESET_AUTH_STATE
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  test:"ok",
  alertMessage: '',
  showMessage: false,
  showSuccess: false,
  initURL: '',
  authUser: localStorage.getItem('user_id'),
  showActivatedSuccess: false,
  showUnblockedSuccess: false
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_AUTH_STATE: {
      return {
        loader: false,
        test:"ok",
        alertMessage: '',
        showMessage: false,
        showSuccess: false,
        initURL: '',
        authUser: null,
        showActivatedSuccess: false,
        showUnblockedSuccess: false
      }
    }
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        showSuccess: true,
        // authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/app/main',
        loader: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    case ACTIVATE_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        showActivatedSuccess: true
      }
    }
    case UNBLOCK_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        showUnblockedSuccess: true
      }
    }
    default:
      return state;
  }
}
