import React from 'react';
import Link from '@material-ui/core/Link';
import IntlMessages from 'util/IntlMessages';

const Footer = () => {
    return (
      <footer className="app-footer">

      <small className="d-inline-block">
        <Link color="inherit" href="https://www.hamiltoncompany.com/privacy-policy"><IntlMessages id="footer.privacyPolicy"/></Link><br/>
        <Link color="inherit" href="https://www.hamiltoncompany.com/general-terms-and-conditions"><IntlMessages id="footer.terms"/></Link><br/>
        <Link color="inherit" href="https://www.hamiltoncompany.com/terms-of-use"><IntlMessages id="footer.termsOfUse"/></Link>
      </small>

      <small className="d-inline-block">
         &copy;&nbsp;<IntlMessages id={"footer.rightsReserved"}/>
      </small>

      <small className="d-inline-block">
        Hamilton LyncStore 700<br/>
        <IntlMessages id={"footer.softwareVersion"}/> 1.1.0<br/>
        <Link color="inherit" href="https://api.mllyncstore700.com/swagger-ui.html"><IntlMessages id="footer.apiDoc"/></Link>
      </small>

      </footer>
    );
  }
;

export default Footer;
