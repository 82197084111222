import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  FORGOT_PASSWORD_USER,
  ACTIVATE_USER,
  ACTIVATE_USER_SUCCESS,
  UNBLOCK_USER,
  UNBLOCK_USER_SUCCESS,
  RESET_AUTH_STATE
} from 'constants/ActionTypes';


export const resetAuthState = () => {
  return {
    type: RESET_AUTH_STATE
  };
};

export const userForgotPsw = (email) => {
  return {
    type: FORGOT_PASSWORD_USER,
    payload: email
  };
};

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};

export const activateUser = (activationId) => {
  return {
    type: ACTIVATE_USER,
    payload: activationId 
  }
};

export const userActivateSuccess = () => {
  return {
    type: ACTIVATE_USER_SUCCESS,
  }
};

export const unblockUser = (unblockId) => {
  return {
    type: UNBLOCK_USER,
    payload: unblockId 
  }
};

export const userUnblockSuccess = () => {
  return {
    type: UNBLOCK_USER_SUCCESS,
  }
};