import React from "react";
import { Card } from 'reactstrap';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField'
import Divider from '@material-ui/core/Divider';
import Button from "@material-ui/core/Button";
import ListSubheader from '@material-ui/core/ListSubheader';
import IntlMessages from 'util/IntlMessages';
import { injectIntl } from "react-intl";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ContainerHeader from "components/ContainerHeader/index";
import InputAdornment from '@material-ui/core/InputAdornment';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FormattedMessage } from "react-intl";
import ProgressWithAbort from '../ProgressWithAbort';
import { WS_MESSAGE_TYPE, WS_URL, WS_DEVICE_TOPIC, WS_TO_DEVICE, WS_TIMEOUT } from '../../../../../constants/WebSocket'

const StompJs = require('@stomp/stompjs');
const uuidv1 = require('uuid/v1');



const styles = {
  formControl: {
    // minWidth: 120,
    // backgroundColor: 'rgb(255,0,0)',
  },
  root: {
    flexGrow: 1,
    // maxWidth: '100%',
  },
  listHeader: {
    backgroundColor: '#a6a6a6',
    fontWeight: 'bold',
    fontSize: '1.2em',
    lineHeight: '1.8em',
    color: '#525252',
    marginTop: '5px'
    // textAlign: 'center'
  },
  notClickableItem: {
    backgroundColor: '#ededed'
  },
  itemPrimary: {
    fontWeight: 'bold',
    fontSize: '1.1em'
  },
  itemSecondary: {
    fontWeight: 'normal',
    fontSize: '1.2em'
  },
  statusOnline: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: 'black'
  },
  statusOffline: {
    fontWeight: 'bold',
    fontSize: '1.2em',
    color: 'black',
  },
  inputLabel: {
    fontWeight: 'bold',
    fontSize: '1.4em',
    color: '#525252',
  },
  divider: {
    // border: 'none',
    // height: '10px',
    // backgroundColor: '#525252',
    // color: '#525252',
  },

}

const syringSizeValues = [10, 25, 50, 100, 250, 500, 1000, 2500, 5000, 10000, 25000, 50000];
const flowRateMap = {
  10: {
    best: 5,
    min: 0.003,
    max: 6.5,
  },
  25: {
    best: 12.5,
    min: 0.007,
    max: 16.5,
  },
  50: {
    best: 25,
    min: 0.014,
    max: 33,
  },
  100: {
    best: 50,
    min: 0.03,
    max: 66.5,
  },
  250: {
    best: 125,
    min: 0.07,
    max: 166.5,
  },
  500: {
    best: 250,
    min: 0.14,
    max: 333,
  },
  1000: {
    best: 500,
    min: 0.3,
    max: 665,
  },
  2500: {
    best: 625,
    min: 0.7,
    max: 1250,
  },
  5000: {
    best: 1250,
    min: 1.4,
    max: 2500,
  },
  10000: {
    best: 2500,
    min: 3,
    max: 5000,
  },
  25000: {
    best: 3125,
    min: 7,
    max: 6000,
  },
  50000: {
    best: 3125,
    min: 14,
    max: 6000,
  },
}

const valveTypeValues = ["config.dualDilutor", "config.dualDispenser", "config.continuousDispenser"];
const triggerSideValues = [{ key: 1, value: "config.either" }, { key: 2, value: "config.left" }, { key: 3, value: "config.right" }];
const probeHandUseValues = ["config.leftHand", "config.rightHand"];
const methodsToLogValues = [
  "5",
  /* "dt_dilution",
   "multi_sample",*/
  "3",
  "4",
  "1",
  "2",
  "6",
  "8",
  "7"
]
const standardReportsValues = [
  "step",
  "l_rate",
  "start_time",
  "trigger",
  "l_vol_pos",
  "r_vol_pos",
  "r_rate",
  "duration",
  "ttl_out",
  "instructional_text",
]
const dryWeightDilutValues = [
  "sample_name_and_purity",
  "balance_information",
  "sample_target",
  "desired_results",
  "solvent_name_and_destiny",
  "solvent_number",
  "run_resulting_data",
  "molecular_mass_and_lot",
  "balance_calibration_info",
  "solvent_target_volume"
]

class DeviceConfiguration extends React.Component {
  constructor() {
    super();

    this.state = {
      dialogOpen: false,
      wasSuccessful: false,
      wasTimeout: false,
      isDeviceOnline: true,
      pendingRequest: null,   // ID of pending request. Not NULL activates CircularProgress
      configLoaded: false,
      configJson: "",
      authHeader: { 'Authorization': 'Bearer ' + localStorage.getItem("token") },

      client: new StompJs.Client({
        reconnectDelay: 300,
        heartbeatIncoming: 4000,
        heartbeatOutgoing: 4000,
        connectHeaders: { ...this.authHeader }
      }),


      //status
      // lastParameterSynchronisation: new Date(),

      serialNumber: '',
      firmwareRuntimeVersion: '',
      fpgaVersion: '',
      syringeCycleCount: '',
      dhcp: true,
      calibration7DayWarning: 0,
      preventUsageOnExpiredCalibration: false,
      maintenanceDate: new Date(),
      maintenanceValidDays: 0,
      maintenance7DayWarning: 0,
      // controller
      systemDate: new Date(),
      systemTime: new Date(),
      screenPowerOff: false,
      internalStorageUsed: '0.0',
      removableStorageUsed: '0.0',
      ramUsed: '0.0',
      macAddress: '',
      systemTraceLogging: false,
      // versions
      pipetteVersion: '',
      aliquotVersion: '',
      dilutionVersion: '',
      dryWeightVersion: '',
      serialDispenseVersion: '',
      titrationVersion: '',
      softwareVersion: '',
      osVersion: '',
      logViewerVersion: '',
      quickStartVersion: '',
      customMethodVersion: '',
      // log
      electronicSignature: 0,
      methodsToLog: [],
      controllerName: 'Default',
      standardReports: [],
      dryWeightDilut: [],
      // hardware
      leftSyringeSize: 0,
      rightSyringeSize: 0,
      leftFlowRate: 0,
      rightFlowRate: 0,
      leftInitFlowRate: 0,
      rightInitFlowRate: 0,
      leftReturnSteps: 0,
      rightReturnSteps: 0,
      leftBackoffSteps: 0,
      rightBackoffSteps: 0,
      leftValve: 0,
      rightValve: 0,
      triggerSide: 1,
      probeHandUse: 0,
      calibrationDate: new Date(),
      calibrationValidDays: 0,
      meanTimeBetweenErrors: 0,
      showCleaningWarning: false,
      cleaningDate: new Date(),
      cleaningValidDays: 0,
      maximumCyclesBeforeCleaning: 0,
      fdaSignature: false

    }

  }

  componentDidMount() {
    //if user did not navigate here though Devices page, but reloading or through history -> redirect
    if (this.props.history.action !== "PUSH") {
      this.props.history.push('/app/main/devices')
    }
    // CONNECT TO WEB SOCKET
    // const client = new StompJs.Client();
    const { client } = this.state;
    const deviceKey = this.props.match.params.deviceKey;
    client.brokerURL = WS_URL;
    client.onConnect = () => {
      client.subscribe(WS_DEVICE_TOPIC + deviceKey, this.onIncomingCallback);
    };
    client.onStompError = (error) => {
      console.log("STOMP ERROR");
      console.log(error);
    };
    client.activate();

  }

  componentWillUnmount() {
    const { client } = this.state;
    client.deactivate();
  }

  getTimestamp() {
    var date = new Date(this.state.systemTime);
// Hours part from the timestamp
    var hours = date.getUTCHours() + "";
// Minutes part from the timestamp
    var minutes = date.getUTCMinutes() + "";
// Seconds part from the timestamp
    var seconds = date.getUTCSeconds() + "";

    return this.addZero(hours) + ":" + this.addZero(minutes) + ":" + this.addZero(seconds);
  }

  //helper function to format any one digit timeunit
  addZero(timeunit) {
    if (timeunit.length < 2) {
      timeunit = "0" + timeunit;
    }

    return timeunit;
  }


  updateConfig = (configJson) => {
    let config = JSON.parse(configJson);

    for (let x1 in config) {
      for (let x2 in config[x1]) {
        if (typeof config[x1][x2] === 'object' && !Array.isArray(config[x1][x2])) {
          for (let x3 in config[x1][x2]) {
            config[x1][x2][x3] = this.state[x3];
          }
        } else {
          config[x1][x2] = this.state[x2];
        }
      }
    }
    return config;
  }

  handleSave = (reqId) => {
    if (this.state.configLoaded) {
      const { client, authHeader } = this.state;
      const deviceKey = this.props.match.params.deviceKey;

      let newConfig = this.updateConfig(this.state.configJson);

      console.log("SENDING CONFIG:");
      console.log(newConfig);

      client.publish({
        destination: WS_TO_DEVICE + deviceKey,
        body: JSON.stringify({ Subject: WS_MESSAGE_TYPE.SET_CONFIGURATION, Id: reqId, Message: newConfig }),
        headers: { ...authHeader }
      });
      setTimeout(() => {
        if (this.state.pendingRequest && !this.state.wasSuccessful) {
          console.log("SAVING TIMEOUT EXPIRED")
          this.setState({
            pendingRequest: null,
            dialogOpen: true,
            wasTimeout: true,
            wasSuccessful: false
          })
        }
      }, WS_TIMEOUT);



    }

  }

  processReceivedConfig = (jsonMsg) => {
    console.log("PROCESSING MESSAGE");
    // console.log(jsonMsg);
    // console.log("PARSED JSON")
    const config = JSON.parse(jsonMsg);
    // console.log(config);

    let newState = {
      pendingRequest: null,
      configLoaded: true,
      configJson: jsonMsg
    };

    // flatten config file
    for (let x1 in config) {
      let first = config[x1];
      for (let x2 in first) {
        let second = first[x2];
        if (typeof first[x2] === 'object' && !Array.isArray(first[x2])) {
          for (let x3 in second) {
            newState[x3] = second[x3];
          }
        } else {
          newState[x2] = second;
        }
      }
    }
    // console.log(newState);

    this.setState(newState, () => { console.log("LOADED"); });

  }

  // called when the client receives a STOMP message from the server
  onIncomingCallback = (message) => {
    console.log("INCOMING (in Configuration)");
    if (message.body) {
      const body = JSON.parse(message.body)
      // console.log(body);
      if (body.Subject === undefined) {
        if (body.messageType === WS_MESSAGE_TYPE.DEVICE_ONLINE) {
          console.log("DEVICE CONNECTED")
          this.setState({ isDeviceOnline: true });

        } else if (body.messageType === WS_MESSAGE_TYPE.DEVICE_OFFLINE) {
          console.log("DEVICE DISCONNECTED");
          this.setState({ isDeviceOnline: false });
        } else {
          console.log('Unknown messageType:' + body.messageType)
        }
      } else if (this.state.pendingRequest === body.Id) {  //accepts only message with answer to request & only if still pending (not timed out)
        if (body.Subject === WS_MESSAGE_TYPE.CONFIGURATION) {
          console.log(JSON.parse(body.Message));
          this.processReceivedConfig(body.Message);
        }
        else if (body.Subject === WS_MESSAGE_TYPE.SAVE_SUCCESSFUL) {
          // Show Dialog to report successful
          this.setState({
            pendingRequest: null,
            dialogOpen: true,
            wasSuccessful: true
          })
        } else if (body.Subject === WS_MESSAGE_TYPE.SAVE_UNSUCCESSFUL) {
          // Show Dialog to report unsuccessful
          this.setState({
            pendingRequest: null,
            dialogOpen: true,
            wasSuccessful: false,
            wasTimeout: false
          })
        } else {
          console.log('Unknown Subject')
        }
      }

    } else throw Error('received no body');;
  };



  handleLoad = (reqId) => {
    console.log("LOAD - SEND GET CONFIG REQUEST " + reqId);
    const deviceKey = this.props.match.params.deviceKey;
    const { client, authHeader } = this.state;

    client.publish({
      destination: WS_TO_DEVICE + deviceKey,
      body: JSON.stringify({ Subject: WS_MESSAGE_TYPE.GET_CONFIGURATION, Id: reqId }),
      headers: { ...authHeader }
    });
    setTimeout(() => {
      if (this.state.pendingRequest && !this.state.configLoaded) {
        console.log("LOADING TIMEOUT EXPIRED")
        this.setState({
          pendingRequest: null,
          dialogOpen: true,
          wasSuccessful: false,
          wasTimeout: true
        })
      }
    }, WS_TIMEOUT);

  }


  handleDialogClose = () => {
    this.setState({
      dialogOpen: false
    })
  }

  // User input validation
  handleSyringeSizeChange = (e) => {
    let newState = {};
    const tarName = e.target.name;
    const value = e.target.value;
    newState[tarName] = value;
    let side = tarName.includes('left') ? 'left' : 'right';
    if (this.state[tarName] !== value) {
      newState[side + 'FlowRate'] = flowRateMap[value].best;
      newState[side + 'InitFlowRate'] = flowRateMap[value].best;
    }

    this.setState(newState);
  }

  handleNumberRangeChange = (e) => {
    const min = parseFloat(e.target.min);
    const max = parseFloat(e.target.max);
    let value = parseFloat(e.target.value);
    let newState = {};
    value = Math.min(Math.max(value, min), max);
    newState[e.target.name] = value;
    console.log(newState)
    this.setState(newState)
  }

  handleAbort = () => {
    this.setState({
      pendingRequest: null,
      dialogOpen: false,
      wasSuccessful: false,
      wasTimeout: false
    })
  }

  render() {
    const { intl, classes, history } = this.props;
    const { configLoaded } = this.state;

    const leftFrm = flowRateMap[this.state.leftSyringeSize];
    const rightFrm = flowRateMap[this.state.rightSyringeSize];

    return (
      <div>

        <ContainerHeader match={{ path: history.location.pathname, url: history.location.pathname }} title={<IntlMessages id="config.configuration" />} />

        <div style={{ padding: 15 }}>
          <Grid container justify="center" spacing={2}>
            <Grid key={0} item>
              <Button variant="contained" color="primary" disabled={!this.state.isDeviceOnline}
                onClick={() => { const reqId = uuidv1(); this.setState({ pendingRequest: reqId }, () => this.handleLoad(reqId)) }}
              ><FormattedMessage id="config.LoadConfiguration" /></Button>
            </Grid>
            <Grid key={1} item>
              {configLoaded &&
                <Button variant="contained" color="primary" disabled={!this.state.isDeviceOnline}
                  onClick={() => { const reqId = uuidv1(); this.setState({ pendingRequest: reqId, wasSuccessful: false }, () => this.handleSave(reqId)) }}
                ><FormattedMessage id="config.SaveConfiguration" /></Button>
              }
            </Grid>
          </Grid>
        </div>

        {!configLoaded && <div className="row mb-md-3">
          <div className="col-lg-4 col-sm-6 col-12">
            <Card>
              <List
                component="nav"
                subheader={<ListSubheader component="div" className={classes.listHeader}><IntlMessages id="config.status" /></ListSubheader>}
                className={classes.root}
              >
                <Divider className={classes.divider} />

                <ListItem >
                  <ListItemText
                    primary={<IntlMessages id="config.connectionStatus" />}
                    secondary={<FormattedMessage id={this.state.isDeviceOnline ? "config.CONNECTED" : "config.DISCONNECTED"} />}
                    classes={{ primary: classes.itemPrimary, secondary: this.state.isDeviceOnline ? classes.statusOnline : classes.statusOffline }}
                  />
                </ListItem>
                <Divider className={classes.divider} />

              </List>
            </Card>
          </div>
        </div>}

        {configLoaded && <div className="row mb-md-3">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="col-lg-4 col-sm-6 col-12">

              <Card>
                <List
                  component="nav"
                  subheader={<ListSubheader component="div" className={classes.listHeader}><IntlMessages id="config.status" /></ListSubheader>}
                  className={classes.root}
                >
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.connectionStatus" />}
                      secondary={<FormattedMessage id={this.state.isDeviceOnline ? "config.CONNECTED" : "config.DISCONNECTED"} />}
                      classes={{ primary: classes.itemPrimary, secondary: this.state.isDeviceOnline ? classes.statusOnline : classes.statusOffline }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                </List>

                <List
                  component="nav"
                  subheader={<ListSubheader component="div" className={classes.listHeader}><IntlMessages id="config.controller" /></ListSubheader>}
                  className={classes.root}
                >
                  <Divider className={classes.divider} />
                  <ListItem >
                    <FormControl
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                    >
                      <KeyboardDatePicker
                        disabled={true}
                        variant="inline"
                        className="date-picker-dialog"
                        label={<IntlMessages id="config.systemDate" />}
                        format="MM/dd/yyyy"
                        value={this.state.systemDate}
                        onChange={(date, value) => this.setState({ systemDate: date })}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </FormControl>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <FormControl
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                    >
                      <TextField
                          classes={{ root: classes.formControl }}
                          className="ConfigInput"
                          fullWidth={true}
                          label={<IntlMessages id="config.systemTime" />}
                          value={this.getTimestamp()}
                          onChange={(date, value) => this.setState({ systemTime: date })}
                          disabled={true}
                      />
                    </FormControl>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.screenPowerOff" />}
                      value={this.state.screenPowerOff}
                      onChange={e => this.setState({ screenPowerOff: e.target.value })}
                      disabled={true}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.internalStorageUsed" />}
                      value={this.state.internalStorageUsed}
                      onChange={e => this.setState({ internalStorageUsed: e.target.value })}
                      disabled={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.removableStorageUsed" />}
                      value={this.state.removableStorageUsed}
                      onChange={e => this.setState({ removableStorageUsed: e.target.value })}
                      disabled={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.ramUsed" />}
                      value={this.state.ramUsed}
                      onChange={e => this.setState({ ramUsed: e.target.value })}
                      disabled={true}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.macAddress" />}
                      value={this.state.macAddress}
                      onChange={e => this.setState({ macAddress: e.target.value })}
                      disabled={true}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.systemTraceLogging" />}
                      value={this.state.systemTraceLogging}
                      onChange={e => this.setState({ systemTraceLogging: e.target.value })}
                      disabled={true}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />
                </List>

                <List
                  component="nav"
                  subheader={<ListSubheader component="div" className={classes.listHeader}><IntlMessages id="config.versions" /></ListSubheader>}
                  className={classes.root}
                >
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.pipetteVersion" />}
                      secondary={this.state.pipetteVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.aliquotVersion" />}
                      secondary={this.state.aliquotVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.dilutionVersion" />}
                      secondary={this.state.dilutionVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.dryWeightVersion" />}
                      secondary={this.state.dryWeightVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.serialDispenseVersion" />}
                      secondary={this.state.serialDispenseVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.titrationVersion" />}
                      secondary={this.state.titrationVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.softwareVersion" />}
                      secondary={this.state.softwareVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.osVersion" />}
                      secondary={this.state.osVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.seriesPumpVersion" />}
                      secondary={this.state.seriesPumpVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.logViewerVersion" />}
                      secondary={this.state.logViewerVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.quickStartVersion" />}
                      secondary={this.state.quickStartVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <ListItemText
                      primary={<IntlMessages id="config.customMethodVersion" />}
                      secondary={this.state.customMethodVersion}
                      classes={{ primary: classes.itemPrimary, secondary: classes.itemSecondary }}
                    />
                  </ListItem>
                  <Divider className={classes.divider} />

                </List>


              </Card>
            </div>


            <div className="col-lg-4 col-sm-6 col-12">
              <Card>
                <List
                  component="nav"
                  subheader={<ListSubheader component="div" className={classes.listHeader}><IntlMessages id="config.support" /></ListSubheader>}
                  className={classes.root}
                >
                  <Divider className={classes.divider} />
                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.serialNumber" />}
                      value={this.state.serialNumber}
                      onChange={e => this.setState({ serialNumber: e.target.value })}
                      disabled={true}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.firmwareRuntimeVersion" />}
                      value={this.state.firmwareRuntimeVersion}
                      onChange={e => this.setState({ firmwareRuntimeVersion: e.target.value })}
                      disabled={true}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.fpgaVersion" />}
                      value={this.state.fpgaVersion}
                      onChange={e => this.setState({ fpgaVersion: e.target.value })}
                      disabled={true}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />


                  <ListItem>
                    <TextField
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.syringeCycleCount" />}
                      value={this.state.syringeCycleCount}
                      onChange={e => this.setState({ syringeCycleCount: e.target.value })}
                      disabled={true}
                    //InputLabelProps={{disabled:false, disableAnimation: true, shrink:true, required: false }}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.dhcp" />}
                      value={this.state.dhcp}
                      onChange={e => this.setState({ dhcp: e.target.value })}
                      disabled={true}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />


                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.calibration7DayWarning" />}
                      value={this.state.calibration7DayWarning}
                      onChange={e => this.setState({ calibration7DayWarning: e.target.value })}
                      disabled={false}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <FormControl
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                    >
                      <KeyboardDatePicker
                        disabled={false}
                        variant="inline"
                        className="date-picker-dialog"
                        label={<IntlMessages id="config.calibrationDate" />}
                        format="MM/dd/yyyy"
                        value={this.state.calibrationDate}
                        onChange={(date, value) => this.setState({ calibrationDate: date.valueOf() })}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />

                    </FormControl>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      name="calibrationValidDays"
                      label={<IntlMessages id="config.calibrationValidDays" />}
                      inputProps={{ min: 7, max: 9999, step: 1 }}
                      value={this.state.calibrationValidDays}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.preventUsageOnExpiredCalibration" />}
                      value={this.state.preventUsageOnExpiredCalibration}
                      onChange={e => this.setState({ preventUsageOnExpiredCalibration: e.target.value })}
                      disabled={false}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <FormControl
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                    >
                      <KeyboardDatePicker
                        disabled={false}
                        variant="inline"
                        className="date-picker-dialog"
                        label={<IntlMessages id="config.maintenanceDate" />}
                        format="MM/dd/yyyy"
                        value={this.state.maintenanceDate}
                        onChange={(date, value) => this.setState({ maintenanceDate: date.valueOf() })}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />

                    </FormControl>
                  </ListItem>
                  <Divider className={classes.divider} />


                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      name="maintenanceValidDays"
                      label={<IntlMessages id="config.maintenanceValidDays" />}
                      inputProps={{ min: 7, max: 9999, step: 1 }}
                      value={this.state.maintenanceValidDays}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.maintenance7DayWarning" />}
                      value={this.state.maintenance7DayWarning}
                      onChange={e => this.setState({ maintenance7DayWarning: e.target.value })}
                      disabled={false}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.meanTimeBetweenErrors" />}
                      // inputProps={{ min: 1, max: 365, step: 1 }}
                      value={this.state.meanTimeBetweenErrors}
                      // onChange={this.handleNumberRangeChange}
                      disabled={true}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.showCleaningWarning" />}
                      value={this.state.showCleaningWarning}
                      onChange={e => this.setState({ showCleaningWarning: e.target.value })}
                      disabled={false}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem >
                    <FormControl
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                    >
                      <KeyboardDatePicker
                        disabled={false}
                        variant="inline"
                        className="date-picker-dialog"
                        label={<IntlMessages id="config.cleaningDate" />}
                        format="MM/dd/yyyy"
                        value={this.state.cleaningDate}
                        onChange={(date, value) => this.setState({ cleaningDate: date.valueOf() })}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />

                    </FormControl>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      name="cleaningValidDays"
                      label={<IntlMessages id="config.cleaningValidDays" />}
                      inputProps={{ min: 7, max: 9999, step: 1 }}
                      value={this.state.cleaningValidDays}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      name="maximumCyclesBeforeCleaning"
                      label={<IntlMessages id="config.maximumCyclesBeforeCleaning" />}
                      inputProps={{ min: 7, max: 9999, step: 1 }}
                      value={this.state.maximumCyclesBeforeCleaning}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />
                </List>

              </Card>
            </div>


            <div className="col-lg-4 col-sm-6 col-12">
              <Card>
                <List
                  component="nav"
                  subheader={<ListSubheader component="div" className={classes.listHeader}><IntlMessages id="config.logSettings" /></ListSubheader>}
                  className={classes.root}
                >
                  <Divider className={classes.divider} />
                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.electronicSignature" />}
                      value={this.state.electronicSignature}
                      onChange={e => this.setState({ electronicSignature: e.target.value })}
                      disabled={false}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />


                  <ListItem>
                    <TextField
                      select
                      SelectProps={{ multiple: true, renderValue: (selected) => selected.map(x => intl.formatMessage({ id: `config.${x}` })).join(', ') }}
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.methodsToLog" />}
                      value={this.state.methodsToLog}
                      onChange={e => { console.log(e.target.value); this.setState({ methodsToLog: e.target.value }) }}
                      disabled={false}
                    >
                      {methodsToLogValues.map((x, idx) => (
                        <MenuItem key={x} value={x}>
                          <Checkbox checked={this.state.methodsToLog.indexOf(x) > -1} />
                          <ListItemText primary={intl.formatMessage({ id: `config.${x}` })} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField

                      type="text"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.controllerName" />}
                      value={this.state.controllerName}
                      onChange={e => this.setState({ controllerName: e.target.value })}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      SelectProps={{ multiple: true, renderValue: (selected) => selected.map(x => intl.formatMessage({ id: `config.${x}` })).join(', ') }}
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.standardReports" />}
                      value={this.state.standardReports}
                      onChange={e => { this.setState({ standardReports: e.target.value }) }}
                      disabled={false}
                    >
                      {standardReportsValues.map((x, idx) => (
                        <MenuItem key={x} value={x}>
                          <Checkbox checked={this.state.standardReports.indexOf(x) > -1} />
                          <ListItemText primary={intl.formatMessage({ id: `config.${x}` })} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  {/* <ListItem>
                  <TextField
                    select
                    SelectProps={{multiple: true, renderValue: (selected)=>selected.map(x=>intl.formatMessage({id: `config.${x}`})).join(', ') }}
                    classes={{root: classes.formControl}}
                    className="ConfigInput"
                    fullWidth={true}
                    label={<IntlMessages id="config.dryWeightDilut"/>}
                    value={this.state.dryWeightDilut}
                    onChange={ e => { this.setState({dryWeightDilut: e.target.value}) }}
                    disabled={false}
                  >
                    {dryWeightDilutValues.map((x, idx) => (
                      <MenuItem key={x} value={x}>
                        <Checkbox checked={this.state.dryWeightDilut.indexOf(x) > -1} />
                        <ListItemText primary={intl.formatMessage({id: `config.${x}`}) } />
                      </MenuItem>
                    ))}
                  </TextField>
                </ListItem>
                <Divider className={classes.divider} /> */}

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.fdaSignature" />}
                      value={this.state.fdaSignature}
                      onChange={e => this.setState({ fdaSignature: e.target.value })}
                      disabled={false}
                    >
                      <MenuItem value={false}><IntlMessages id="config.off" /></MenuItem>
                      <MenuItem value={true}><IntlMessages id="config.on" /></MenuItem>
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />



                </List>

                <List
                  component="nav"
                  subheader={<ListSubheader component="div" className={classes.listHeader}><IntlMessages id="config.hardwareSettings" /></ListSubheader>}
                  className={classes.root}
                >
                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      name="leftSyringeSize"
                      label={<IntlMessages id="config.leftSyringeSize" />}
                      value={this.state.leftSyringeSize}
                      onChange={this.handleSyringeSizeChange}
                      disabled={false}
                    >
                      {syringSizeValues.map((x, idx) => (
                        <MenuItem key={x} value={x}>
                          <ListItemText primary={x} />
                        </MenuItem>
                      ))}
                    </TextField>

                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      name="rightSyringeSize"
                      label={<IntlMessages id="config.rightSyringeSize" />}
                      value={this.state.rightSyringeSize}
                      onChange={this.handleSyringeSizeChange}
                      disabled={false}
                    >
                      {syringSizeValues.map((x, idx) => (
                        <MenuItem key={x} value={x}>
                          <ListItemText primary={x} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: leftFrm.min, max: leftFrm.max, step: 0.0001 }}
                      name='leftFlowRate'
                      label={<IntlMessages id="config.leftFlowRate" />}
                      value={this.state.leftFlowRate}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                      helperText={`${intl.formatMessage({ id: 'config.recommended' })}: ${leftFrm.best}`}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: rightFrm.min, max: rightFrm.max, step: 0.0001 }}
                      name='rightFlowRate'
                      label={<IntlMessages id="config.rightFlowRate" />}
                      value={this.state.rightFlowRate}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                      helperText={`${intl.formatMessage({ id: 'config.recommended' })}: ${rightFrm.best}`}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: leftFrm.min, max: leftFrm.max, step: 0.0001 }}
                      name='leftInitFlowRate'
                      label={<IntlMessages id="config.leftInitFlowRate" />}
                      value={this.state.leftInitFlowRate}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                      helperText={`${intl.formatMessage({ id: 'config.recommended' })}: ${leftFrm.best}`}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: rightFrm.min, max: rightFrm.max, step: 0.0001 }}
                      name='rightInitFlowRate'
                      label={<IntlMessages id="config.rightInitFlowRate" />}
                      value={this.state.rightInitFlowRate}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                      helperText={`${intl.formatMessage({ id: 'config.recommended' })}: ${rightFrm.best}`}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: 0, max: 1000, step: 1 }}
                      name='leftReturnSteps'
                      label={<IntlMessages id="config.leftReturnSteps" />}
                      value={this.state.leftReturnSteps}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: 0, max: 1000, step: 1 }}
                      name='rightReturnSteps'
                      label={<IntlMessages id="config.rightReturnSteps" />}
                      value={this.state.rightReturnSteps}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: 0, max: 1000, step: 1 }}
                      name='leftBackoffSteps'
                      label={<IntlMessages id="config.leftBackoffSteps" />}
                      value={this.state.leftBackoffSteps}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      type="number"
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      inputProps={{ min: 0, max: 1000, step: 1 }}
                      name='rightBackoffSteps'
                      label={<IntlMessages id="config.rightBackoffSteps" />}
                      value={this.state.rightBackoffSteps}
                      onChange={this.handleNumberRangeChange}
                      disabled={false}
                    >
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.leftValve" />}
                      value={this.state.leftValve}
                      onChange={e => this.setState({ leftValve: e.target.value, rightValve: e.target.value })}
                      disabled={false}
                    >
                      {valveTypeValues.map((x, idx) => (
                        <MenuItem key={idx} value={idx}>
                          <ListItemText primary={intl.formatMessage({ id: x })} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.rightValve" />}
                      value={this.state.rightValve}
                      onChange={e => this.setState({ leftValve: e.target.value, rightValve: e.target.value })}
                      disabled={false}
                    >
                      {valveTypeValues.map((x, idx) => (
                        <MenuItem key={idx} value={idx}>
                          <ListItemText primary={intl.formatMessage({ id: x })} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.triggerSide" />}
                      value={this.state.triggerSide}
                      onChange={e => this.setState({ triggerSide: e.target.value })}
                      disabled={false}
                    >
                      {triggerSideValues.map((x, idx) => (
                        <MenuItem key={idx} value={x.key}>
                          <ListItemText primary={intl.formatMessage({ id: x.value })} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />


                  <ListItem>
                    <TextField
                      select
                      classes={{ root: classes.formControl }}
                      className="ConfigInput"
                      fullWidth={true}
                      label={<IntlMessages id="config.probeHandUse" />}
                      value={this.state.probeHandUse}
                      onChange={e => this.setState({ probeHandUse: e.target.value })}
                      disabled={false}
                    >
                      {probeHandUseValues.map((x, idx) => (
                        <MenuItem key={idx} value={idx}>
                          <ListItemText primary={intl.formatMessage({ id: x })} />
                        </MenuItem>
                      ))}
                    </TextField>
                  </ListItem>
                  <Divider className={classes.divider} />

                </List>

              </Card>
            </div>
          </MuiPickersUtilsProvider>
        </div>}

        {this.state.pendingRequest && <ProgressWithAbort onAbort={this.handleAbort} />}
        <Dialog
          open={this.state.dialogOpen}
          onClose={this.handleDialogClose}
        >
          <DialogTitle id="verify-dialog-title"><FormattedMessage id="config.operationResult" /></DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {
                this.state.wasTimeout ?
                  <FormattedMessage id="config.dialogTimeout" /> :
                  this.state.wasSuccessful ?
                    <FormattedMessage id="config.dialogSuccess" /> :
                    <FormattedMessage id="config.dialogFailure" />
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleDialogClose} color="primary" autoFocus>
              <FormattedMessage id="dialog.ok" />
            </Button>
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

// export default DeviceConfiguration;
export default injectIntl(withStyles(styles)(DeviceConfiguration));