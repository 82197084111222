import React from "react";
import { tableIcons } from '../../../../../constants/TableConstants'
import MaterialTable, {MTableToolbar} from 'material-table'
import apiService from "../../../../../service/apiService";
import CustomFilterRow from '../CustomFilterRow';
import { injectIntl } from "react-intl";
import MuiLink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Moment from "react-moment";

const axios = require('axios');

class AuditLogs extends React.Component {

    handleExportCSV = () => {
        let token = localStorage.getItem("token");
        axios.get(`${apiService.BASE_URL}download/audit/logs`,{
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer '+ token,
                'Accept': 'application/octet-stream'
            }
        })
            .then(res => {
                const blob = res.data;
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', "export.csv");
                document.body.appendChild(link);
                link.click();

            })
            .catch(err=>err)
    }
    
    // function that gets remote data
    fetchData = query => new Promise((resolve, reject) => {
        // console.log(this.props);
        console.log("QUERY");
        console.log(query);
        
        let url = apiService.BASE_URL+"audit/logs/";

        let token = localStorage.getItem("token");
        let config = {
            params: {
                // search: query.search, 
                size: query.pageSize,
                page: query.page,
                sort: query.orderBy ? `${query.orderBy.field},${query.orderDirection}` : '',
            },
            headers: {
                'Authorization': 'Bearer '+ token,
            }
        }

        query.filters.forEach((filt, index, array) => {
            if(filt.column.field !== 'createdAt')
                config.params[filt.column.field] = filt.value;
            else{ 
                //Add date filter
                if(filt.value[0] !== undefined) config.params["from"] = filt.value[0];
                if(filt.value[1] !== undefined) config.params["to"] = filt.value[1];
            }
        })

        axios.get(url, config)
        .then(result => {
            console.log("RESULT")
            console.log(result.data)
            
            if(result.status===200){
                resolve({
                    data: result.data.content,
                    page: result.data.pageable.pageNumber, // current page number
                    totalCount: result.data.totalElements,  // total row number
                })
            }else{
                reject({responseStatus: result.status})
            }
        })
    })


    render() {
        
        const { intl } = this.props;
    
        return (
            <div style={{ maxWidth: '100%' }}>
                
                <MaterialTable
                    title={intl.formatMessage({id: 'appModule.cloudAuditTrail'})}
                    columns={[
                    {
                        field: "createdAt",
                        title: intl.formatMessage({id: 'audit.timestamp'}),
                        type: "datetime-range",
                        defaultSort: "desc",
                        minWidth:220,
                        render: (data)=> {
                            const dateToFormat = new Date(data.createdAt);
                            return <Moment format="YYYY/MM/DD  HH:mm:ss " date={dateToFormat}/>;
                        }
                    },
                    {
                        field: "createdById",
                        title: intl.formatMessage({id: 'audit.userId'}),
                        type: "text",
                        filtering: false
                    },
                    {
                        field: "event",
                        title: intl.formatMessage({id: 'audit.eventId'}),
                        type: "text",
                        filtering: false
                    },
                    {
                        field: "originalValue",
                        title: intl.formatMessage({id: 'audit.originalValue'}),
                        type: "text",
                        filtering: false
                    },
                    {
                        field: "newValue",
                        title: intl.formatMessage({id: 'audit.newValue'}),
                        type: "text",
                        filtering: false,
                        maxWidth:450,
                        overflow: "hidden",
                        render: rowData => {
                            if (!rowData || !rowData.newValue) {
                                return "";
                            } else if (rowData.newValue.length < 60) {
                                return rowData.newValue;
                            } else {
                                return rowData.newValue.substring(0, 60) + "...";
                            }
                        }
                    },
                    {
                        field: "comment",
                        title: intl.formatMessage({id: 'audit.comment'}),
                        type: "text",
                        filtering: false
                    },
                    {
                        field: "details",
                        title: intl.formatMessage({id: 'audit.details'}),
                        filtering: false,
                        render: rowData => {
                            let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(rowData));
                            let fileName = rowData.createdAt;
                            return (<Typography>
                                <MuiLink href={"data:"+data} download={`${fileName}.json`} underline='always'>JSON</MuiLink>
                            </Typography>)
                        }
                    }
                    ]}
                    data={this.fetchData}
                    icons={tableIcons}
                    options={{
                        filtering: true,
                        search: false,
                        exportButton: true,
                        pageSize: 10,
                        draggable: false,
                        exportCsv: (columns, data) => {
                            this.handleExportCSV();
                        }
                    }}
                    components={{
                        FilterRow: CustomFilterRow,
                        Toolbar: props => <MTableToolbar {...props} />,
                    }}
                    localization={{
                        header: {
                            actions: ''
                        },
                        toolbar: {
                            searchTooltip: intl.formatMessage({id: 'table.search'}),
                            searchPlaceholder: intl.formatMessage({id: 'table.search'}),
                            exportTitle: intl.formatMessage({id: 'table.export'}),
                            exportAriaLabel: intl.formatMessage({id: 'table.export'}),
                            exportName: intl.formatMessage({id: 'table.exportAsCSV'}),
                        },
                        body: {
                            emptyDataSourceMessage: intl.formatMessage({id: 'table.emptyDataSourceMessage'}),
                            filterRow: {
                                dateTimePickerLocalization: intl.locale,
                                filterTooltip: intl.formatMessage({id: 'table.filterTooltip'}),
                            }
                        },
                        pagination: {
                            labelRowsSelect: intl.formatMessage({id: 'table.rows'}),
                            labelDisplayedRows: intl.formatMessage({id: 'table.page'}),
                            firstTooltip: intl.formatMessage({id: 'table.firstPage'}),
                            previousTooltip: intl.formatMessage({id: 'table.previousPage'}),
                            nextTooltip: intl.formatMessage({id: 'table.nextPage'}),
                            lastTooltip: intl.formatMessage({id: 'table.lastPage'}),
                        }
                    }}
                />

            </div>
        );
    }
}

export default injectIntl(AuditLogs);
