import React from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, BreadcrumbItem} from 'reactstrap';
import { injectIntl } from "react-intl";

const getDisplayString = (sub) => {
  const arr = sub.split("-");
  let str;
  if (arr.length > 1) {
    str = arr[0].charAt(0).toUpperCase() + arr[0].slice(1) + " " + arr[1].charAt(0).toUpperCase() + arr[1].slice(1) 
    if(arr.length > 2){
      str = str + " " + arr[2].charAt(0).toUpperCase() + arr[2].slice(1) 
      if(arr.length > 3){
        str = str + ".."
      }
    }
    return str
  } else {
    return sub.charAt(0) + sub.slice(1)
  }

};
const getUrlString = (path, sub, index) => {
  if (index === 0) {
    // return '#/';
    return '/';
  } else {
    // return '#/' + path.split(sub)[0] + sub;
    return '/' + path.split(sub)[0] + sub;
  }
};

// List of modules for which a specific item is selected
// (this excludes the selected item name from translation to avoid error message)
const excludeNext = ["devices","logs"];

const ContainerHeader = ({intl, title, match}) => {
  const path = match.path.substr(1);
  const subPath = path.split('/');
  return (
    <div className="page-heading d-sm-flex justify-content-sm-between align-items-sm-center">
      <h2 className="title mb-3 mb-sm-0">{title}</h2>

      <Breadcrumb className="mb-0" tag="nav">
        {subPath.map((sub, index) => {
            let preDisplayStr = sub;
            if(!excludeNext.includes(subPath[index-1])) preDisplayStr = intl.formatMessage({id: sub});
            return <BreadcrumbItem active={subPath.length === index + 1}
                                   tag={subPath.length === index + 1 ? "span" : Link} key={index}
                                   to={getUrlString(path, sub, index)}
                                   >
                                     {getDisplayString(preDisplayStr)}
                                   </BreadcrumbItem>
          }
        )}
      </Breadcrumb>
    </div>
  )
};

export default injectIntl(ContainerHeader);

