import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import { connect } from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { userSignOut } from 'actions/Auth';
import IntlMessages from 'util/IntlMessages';
import {Link} from 'react-router-dom';

class UserInfo extends React.Component {

  state = {
    anchorEl: null,
    open: false,
    username: "",
    avatar: ""
  };

  handleClick = event => {
    this.setState({ open: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = () => {
    this.setState({ open: false });
  };

  render() {
    var { username, avatar } = this.props;
    var user = JSON.parse(localStorage.getItem("user"));
    username = user&&(user.firstName + " " + user.lastName);
    avatar = user&&(user.firstName.charAt(0) + user.lastName.charAt(0));
    
    return (
      <div className="user-profile d-flex flex-row align-items-center">
        { user && <React.Fragment> 
            <Avatar
            className="user-avatar "
          >{avatar}</Avatar>
          <div className="user-detail">
            <h4 className="user-name" onClick={this.handleClick}>{username} <i
              className="zmdi zmdi-caret-down zmdi-hc-fw align-middle" />
            </h4>
            {/* <small>{user.role}</small> */}
          </div>
          <Menu className="user-info"
            id="simple-menu"
            anchorEl={this.state.anchorEl}
            open={this.state.open}
            onClose={this.handleRequestClose}
            PaperProps={{
              style: {
                minWidth: 120,
                paddingTop: 0,
                paddingBottom: 0
              }
            }}
          >
          <Link to="/app/main/profile" style={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.87)' }}>
            <MenuItem onClick={this.handleRequestClose}>
              <i className="zmdi zmdi-account zmdi-hc-fw mr-2" />
              <IntlMessages id="userPopup.profile" />
            </MenuItem>
            </Link>

            <MenuItem onClick={() => {
              this.handleRequestClose();
              this.props.userSignOut()
            }}>
              <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2" />

              <IntlMessages id="userPopup.logout" />
            </MenuItem>
          </Menu>
        </React.Fragment>}
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { locale } = settings;
  return { locale }
};
export default connect(mapStateToProps, { userSignOut })(UserInfo);


