import React from "react";
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { Refresh } from '@material-ui/icons'
import MaterialTable from 'material-table'
import IntlMessages from 'util/IntlMessages';

import apiService from "../../../../../service/apiService";

import { appModes, getCurrentAppMode } from "../../../../../service/apiService";

import axios from "axios";
import BaseDialog from "./BaseDialog";
import UserInfoForm from "./UserInfoForm";
import { injectIntl } from "react-intl";

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const columns = [
    {
        field: "email",
        title: <IntlMessages id={"userManagement.email"}/>,
        type: "text"
    },
    {
        field: "firstName",
        title: <IntlMessages id={"userManagement.firstName"}/>,
        type: "text"
    },
    {
        field: "lastName",
        title: <IntlMessages id={"userManagement.lastName"}/>,
        type: "text"
    },
    // {
    //     field: "role",
    //     title: "Role",
    //     lookup: {
    //         ROLE_SUPER_ADMIN: "SUPER_ADMIN",
    //         ROLE_ADMIN: "ADMIN",
    //         ROLE_USER: "USER"
    //     },
    //     filterCellStyle:{
    //         paddingTop: "0px"
    //     }
    // }
];



const options = {
    search: false,
    exportButton: false,
    actionsColumnIndex: -1,
    pageSize: 10,
    filtering: true
};



const fetchData = query =>
    new Promise((resolve, reject) => {
        let url = apiService.BASE_URL + "users?";
        url += 'size=' + query.pageSize
        url += '&page=' + (query.page)
        query.filters.forEach((filt, index, array) => {
            url += '&' + filt.column.field + '=' + filt.value;
        })

        let token = localStorage.getItem("token");
        let config = {
            headers: {
                'Authorization': 'Bearer ' + token,
            }
        }


        axios.get(url, config)
            .then(result => {
                if (result.status === 200) {
                    resolve({
                        data: result.data.content,
                        page: result.data.pageable.pageNumber, // current page number
                        totalCount: result.data.totalElements,  // total row number
                    })
                } else {
                    reject({ responseStatus: result.status })
                }
            })
    })



class Users extends React.Component {
    state = {
        dialogOpen: false,
        dialogType: "",
        clickedRow: null,
        tableRef: null
    };

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
    }


    actions = getCurrentAppMode() != appModes.offline ? null :
        [
            //TODO only if admin
            //DELETE
            rowData => ({
                icon: tableIcons.Delete,
                tooltip: 'Delete User',
                onClick: (event, rowData) => {
                    //Open confirmation dialog
                    // console.log(this.tableRef);
                    this.setState({
                        dialogOpen: true,
                        dialogType: "delete",
                        dialogTitle: this.props.intl.formatMessage({ id: "dialog.delete" }),
                        clickedRow: rowData,
                        tableRef: this.tableRef
                    })
                }
            }),
            rowData => ({
                icon: Refresh,
                tooltip: 'Reset password',
                onClick: (event, rowData) => {
                    this.setState({
                        dialogOpen: true,
                        dialogType: "reset",
                        dialogTitle: this.props.intl.formatMessage({ id: "dialog.reset" }),
                        clickedRow: rowData,
                        tableRef: this.tableRef
                    })
                }
            })
            // {
            //     icon: tableIcons.Add,
            //     tooltip: 'Add User',
            //     isFreeAction: true,
            //     onClick: (event) => {
            //         //Open add item dialog
            //         // console.log(this.tableRef);
            //         this.setState({
            //             dialogOpen: true,
            //             dialogType: "new",
            //             dialogTitle: "NEW USER",  //TODO intl
            //             tableRef: this.tableRef
            //         })
            //     }
            // }
        ];


    render() {
        const { intl, history } = this.props;

        return (
            <div style={{ maxWidth: '100%' }}>
                <MaterialTable
                    tableRef={this.tableRef}
                    title={<IntlMessages id={"userManagement"}/>}
                    columns={columns}
                    data={fetchData}
                    icons={tableIcons}
                    options={options}
                    onRowClick={(event, rowData, togglePanel) => {
                        // history.push(history.location.pathname + '/' + rowData.id + '/devices', rowData)
                    }}
                    // editable={{
                    //     onRowUpdate: (newData, oldData) =>
                    //         new Promise((resolve, reject) => {
                    //             resolve()
                    //         }),
                    // }}
                    actions={getCurrentAppMode() == appModes.offline ? this.actions : null}
                />
                <BaseDialog
                    title={this.state.dialogTitle}
                    open={this.state.dialogOpen}
                    type={this.state.dialogType}
                    children={<UserInfoForm />}
                    rowData={this.state.clickedRow}
                    onClose={() => this.setState({ dialogOpen: false })}
                    onOkClick={() => {
                        let token = localStorage.getItem("token");
                        let config = {
                            headers: {
                                'Authorization': 'Bearer ' + token,
                            }
                        };

                        console.warn(config);

                        if (this.state.dialogType === "delete") {
                            axios.delete(apiService.BASE_URL + "users/" + this.state.clickedRow.id, config)
                                .then(res => {
                                    console.log(res);
                                    //close Dialog
                                    this.setState(
                                        { dialogOpen: false },
                                        () => this.tableRef.current && this.tableRef.current.onQueryChange());
                                })
                                .catch(err => err)
                        } else if (this.state.dialogType === "reset") {
                            console.warn(this.state.clickedRow.id);
                            console.warn(config);
                            axios.post(apiService.BASE_URL + "users/resetPassword/" + this.state.clickedRow.id, null, config)
                                .then(res => {
                                    console.log(res);
                                    //close Dialog
                                    this.setState(
                                        { dialogOpen: false },
                                        () => this.tableRef.current && this.tableRef.current.onQueryChange());
                                })
                                .catch(err => err)
                        }
                    }}
                />
            </div>
        );
    }
}

export default injectIntl(Users);
