export const languageData = [
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'chinese',
    locale: 'zh',
    name: 'Chinese',
    icon: 'cn'
  },
  {
    languageId: 'german',
    locale: 'de',
    name: 'German',
    icon: 'de'
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es'
  },
  {
    languageId: 'french',
    locale: 'fr',
    name: 'French',
    icon: 'fr'
  },
  {
    languageId: 'italian',
    locale: 'it',
    name: 'Italian',
    icon: 'it'
  },
  // {
  //   languageId: 'saudi-arabia',
  //   locale: 'ar',
  //   name: 'Arabic',
  //   icon: 'sa'
  // }

];

//looks for language data given locale id
//if not found return the first in the array
export const getLanguageDataFromLocaleId = localeId => {
  return languageData.find( l => { 
    //at start site read browser locale 
    localeId = localeId.split('-')[0];
    return l.locale === localeId;
  }) || languageData[0]
};